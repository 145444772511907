/**
 * Created by andy on 10/12/16.
 */

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'photo'})
export class PhotoPipe implements PipeTransform {


  constructor() {
  }

  transform(value: any, operation: string, argument?: any): string {
    if (!value) return "";

    let lastIndex = value.lastIndexOf('/');

    switch (operation) {

      case 'resize':
        if (lastIndex === -1)
          return value;

        let hostName = value.substring(0, lastIndex + 1);
        let photoName = value.substring(lastIndex);

        return hostName + argument + photoName;
      default:
        return value;
    }


  }
}
