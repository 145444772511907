/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit} from '@angular/core';

// Angular Third Party Lib
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {resizeImage} from "../../shared/services/util";
import {AppService} from "../../shared/services/app.service";
import {PopUpService} from "../../shared/services/popup.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";
import {PopUpForm} from "./popup-form/popup-form";

@Component({
  selector: 'popup',
  template: getTemplate('pages/popup/popup')
})

export class PopUp implements OnInit {

  appEventDisposor;

  options = {
    rows: [],
    selected: null,
    headerHeight: "60px",
    footerHeight: "100px",
    rowHeight: "80px",
    bodyHeight: "640px",
    limit: 8,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };
  dialogPopUpRef: MdDialogRef<PopUpForm>;
  config: MdDialogConfig = new MdDialogConfig();

  resize = resizeImage;

  searchKeyword;

  constructor(private mdDialog: MdDialog,
              private appService: AppService,
              private dialogService: DialogService,
              private popupService: PopUpService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.loadList({page: this.options.page});
  }

  // ngOnDestroy() {
  //   if (this.appEventDisposor)
  //     this.appEventDisposor.unsubscribe();
  // }

  /*****************************
   *        util functions
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.loadList({page: this.options.page});
        break;
    }
  }

  openpopupFormModel(popup?) {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogPopUpRef = this.mdDialog.open(PopUpForm, this.config);
    if (popup)
      this.dialogPopUpRef.componentInstance.popupId = popup._id;

    this.dialogPopUpRef.afterClosed()
      .subscribe((result) => {
        this.loadList({page: this.options.page});
      });
  }

  /*****************************
   *       helper functions
   *****************************/

  loadList(event) {
    this.options.page = event.page;
    let skip = (this.options.page - 1) * this.options.limit;
    let params: any = {
      query:{isDeleted:false},
      limit: this.options.limit,
      skip: skip,
      sort: {_id: -1},
      populate: ['photo']
    };
     if(this.searchKeyword){
      params.query['$or'] = [];
      params.query['$or'].push({title: {$regex: ".*" + this.searchKeyword + ".*"}});
     }
    this.popupService.find(params)
      .subscribe(
        (PopUpsWrapper) => {
          _.forEach(PopUpsWrapper['PopUps'], (popup, index) => {
            popup['no'] = skip + index + 1;
          });
          for(var i =0 ; i < PopUpsWrapper['PopUps'].length;i++){
            if(PopUpsWrapper['PopUps'][i].show)
            PopUpsWrapper['PopUps'][i].show = "보이기";
          else
            PopUpsWrapper['PopUps'][i].show = "숨기기";  
          }
          if(PopUpsWrapper['PopUps'].length > this.options.limit){
            PopUpsWrapper['PopUps'].pop();
          }
          this.options.rows = PopUpsWrapper['PopUps'];
          this.options.count = PopUpsWrapper['total'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  popupRemove(PopUp) {
    this.dialogService.confirm("알림", "정말 지우시겠습니까?")
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .filter(function (x) {
        return x;
      })
      .flatMap((result) => {
        this.dialogService.loadingSubject.next('spinner');
        return this.popupService.remove(PopUp._id);
      })
      .subscribe(
        (result) => {
          this.loadList({page: this.options.page});
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '팝업을 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
    }
}
