/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Injectable, EventEmitter} from "@angular/core";

@Injectable()
export class AppService {

  get user(): any {
    let user = localStorage.getItem('nosework_user');

    try {
      user = JSON.parse(user);
      return user;
    } catch (e) {
      return null;
    }
  };

  set user(_user) {
    if (_user)
      localStorage.setItem('nosework_user', JSON.stringify(_user));
    else
      localStorage.removeItem('nosework_user');
  };

  get token(): any {
    let token = localStorage.getItem('nosework_token');

    try {
      token = JSON.parse(token);
      return token;
    } catch (e) {
      return null;
    }
  };

  set token(_token: any) {
    if (_token)
      localStorage.setItem('nosework_token', JSON.stringify(_token));
    else
      localStorage.removeItem('nosework_token');
  };

  appEvent: EventEmitter<any> = new EventEmitter();

  constructor() {}

  /*****************************
   *        util functions
   *****************************/

  sendEvent(eventName: string, data?: any) {
    let eventData: {name: string, data?: any} = {name: eventName};
    if (data) eventData.data = data;
    this.appEvent.emit(eventData);
  }

}
