/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Angular
import {Component, OnInit} from '@angular/core';

// Project Sources
import {getTemplate} from "../../shared/templates";
import {DeviceService} from "../../shared/services/device.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'push',
  template: getTemplate('pages/push/push')
})
export class Push implements OnInit {

  push;

  constructor(private deviceService: DeviceService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.push = {
      title: '',
      message: '',
      data: {
        url: ''
      }
    };
  }

  /*****************************
   *        util functions
   *****************************/

  isValid(){
    let urlRegExp = new RegExp('^(http|https)://');

    if(!this.push['title'] || this.push['title'].length <= 0) return false;
    else if(!this.push['message'] || this.push['message'].length <= 0) return false;
    else if(this.push['data'].url.length > 0 && !urlRegExp.test(this.push['data'].url)) return false;
    else return true;
  }

  /*****************************
   *       helper functions
   *****************************/

  pushToDevices() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.deviceService.pushToDevices(this.push)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          () => {
            this.dialogService.message("알림", '푸시메시지가 전송되었습니다.');
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        )
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }

  }

}
