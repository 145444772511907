/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Angular
import {NgModule, LOCALE_ID } from "@angular/core";
import {HttpModule} from "@angular/http";
import {BrowserModule, Title} from "@angular/platform-browser";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MdUniqueSelectionDispatcher, MaterialRootModule} from "@angular/material";
import {MdIconRegistry} from "@angular/material";
import {MdDialogModule} from "@angular/material";

// Angular Third Party Lib
import {FileUploadModule} from "../lib/ng2-file-upload/components/file-upload/file-upload.module";
import {HttpInterceptorModule} from "../lib/ng-http-interceptor/http/module";
import {PerfectScrollbarModule, PerfectScrollbarConfigInterface} from 'angular2-perfect-scrollbar';
import { DatepickerModule } from 'angular2-material-datepicker'
import { TinymceModule } from 'angular2-tinymce';

// Project Sources
import {AppComponent} from "./app.component";
import {routing} from "./app.routes";
// src/pages
import {PopUp} from "../pages/popup/popup";
import {PopUpForm} from "../pages/popup/popup-form/popup-form";
import {WebBanner} from "../pages/banner/web-banner/web-banner";
import {WebBannerForm} from "../pages/banner/web-banner/web-banner-form/web-banner-form";
import {WebBannerVideoForm} from '../pages/banner/web-banner/web-banner-form/web-banner-video-form';
import {StoreBanner} from "../pages/banner/store-banner/store-banner";
import {StoreBannerForm} from "../pages/banner/store-banner/store-banner-form/store-banner-form";
import {StoreBannerVideoForm} from "../pages/banner/store-banner/store-banner-form/store-banner-video-form";
import {Faq} from "../pages/faq/faq";
import {FaqForm} from "../pages/faq/faq-form/faq-form";
import {Header} from "../pages/header/header";
import {ChangePasswordForm} from "../pages/header/change-password-form/change-password-form";
import {Login} from "../pages/login/login";
import {Product} from "../pages/product/product";
import {ProductForm} from "../pages/product/product-form/product-form";
import {Serial} from "../pages/serial/serial";
import {SerialForm} from "../pages/serial/serial-form/serial-form";
import {Genuine} from "../pages/genuine/genuine";
import {GenuineForm} from "../pages/genuine/genuine-form/genuine-form";
import {Invoice} from "../pages/invoice/invoice";
import {InvoiceForm} from "../pages/invoice/invoice-form/invoice-form";
import {ChangeStatusForm} from "../pages/invoice/change-status-form/change-status-form";
import {ChangeTransInfoForm} from "../pages/invoice/change-trans-info-form/change-trans-info-form";
import {Push} from "../pages/push/push";
import {Mail} from "../pages/mail/mail";
import {Sidenav} from "../pages/sidenav/sidenav";
import {User} from "../pages/user/user";
import {UserForm} from "../pages/user/user-form/user-form";
import {Version} from "../pages/version/version";
// src/shared/components
import {Datepicker} from "../shared/components/datepicker/datepicker.component";
import {DaumMap} from "../shared/components/daum-map/daum-map";
import {DialogMessage} from "../shared/components/dialog-message/dialog-message.component";
import {DialogService} from "../shared/components/dialog-message/dialog-message.service";
import {LoadingComponent} from "../shared/components/loading/loading.component";
import {PagerComponent} from "../shared/components/pager/pager.component";
// src/shared/directives
import {SanitizeAttribute} from "../shared/directives/sanitize-attribute";
import {VirtualFileInput} from "../shared/directives/virtual-file-input";
// src/shared/pipes
import {LPADPipe} from "../shared/pipes/lpad.pipe";
import {Moment} from "../shared/pipes/moment.pipe";
import {PasswordPipe} from "../shared/pipes/password";
import {PhotoPipe} from "../shared/pipes/photo.pipe";
import {FullYoutubeUrlPipe} from "../shared/pipes/full_youtube_url.pipe";
import { VideoImageTypePipe } from "../shared/pipes/video_image_type.pipe";
// src/shared/services
import {AppService} from "../shared/services/app.service";
import {AuthService} from "../shared/services/auth.service";
import {BannerService} from "../shared/services/banner.service";
import {DeviceService} from "../shared/services/device.service";
import {MailService} from "../shared/services/mail.service";
import {FaqService} from "../shared/services/faq.service";
import {FileService} from "../shared/services/file.service";
import {FileValidateService} from "../shared/services/file.validate.service";
import {ProductService} from "../shared/services/product.service";
import {ReleaseRequestService} from "../shared/services/release-request.service";
import {SerialService} from "../shared/services/serial.service";
import {SidenavService} from "../shared/services/sidenav.service";
import {TransactionService} from "../shared/services/transaction.service";
import {UserService} from "../shared/services/user.service";
import {VersionService} from "../shared/services/version.service";
// src/shared/validators
import {EmailValidator} from "../shared/validators/email-format.validator";
import {EqualValidator} from "../shared/validators/equal.validator";
import {PSDValidator} from "../shared/validators/password-format.validator";

import {PopUpService} from "../shared/services/popup.service";

const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    HttpModule,
    MaterialRootModule,
    FileUploadModule,
    MdDialogModule,
    HttpInterceptorModule.noOverrideHttp(),
    PerfectScrollbarModule.forRoot(PERFECT_SCROLLBAR_CONFIG),
    DatepickerModule,
    TinymceModule.withConfig({language: 'ko_KR'})
  ],
  declarations: [
    AppComponent,
    // src/pages
    PopUp,
    PopUpForm,
    WebBanner,
    WebBannerForm,
    WebBannerVideoForm,
    StoreBanner,
    StoreBannerForm,
    StoreBannerVideoForm,
    Faq,
    FaqForm,
    Header,
    ChangePasswordForm,
    Login,
    Push,
    Mail,
    Sidenav,
    User,
    UserForm,
    Version,
    Product,
    ProductForm,
    Serial,
    SerialForm,
    Genuine,
    GenuineForm,
    Invoice,
    InvoiceForm,
    ChangeStatusForm,
    ChangeTransInfoForm,
    // src/shared/components
    Datepicker,
    DaumMap,
    DialogMessage,
    LoadingComponent,
    PagerComponent,
    // src/shared/directives
    SanitizeAttribute,
    VirtualFileInput,
    // src/shared/pipes
    LPADPipe,
    Moment,
    PasswordPipe,
    PhotoPipe,
    FullYoutubeUrlPipe,
    VideoImageTypePipe,
    // src/shared/validators
    EmailValidator,
    EqualValidator,
    PSDValidator
  ],
  providers: [
    Title,
    MdIconRegistry,
    MdUniqueSelectionDispatcher,
    // src/shared/components
    DialogService,
    // src/shared/services
    AppService,
    AuthService,
    PopUpService,
    BannerService,
    DeviceService,
    MailService,
    FaqService,
    FileService,
    FileValidateService,
    ProductService,
    ReleaseRequestService,
    SerialService,
    SidenavService,
    TransactionService,
    VersionService,
    UserService,
    { provide: LOCALE_ID, useValue: "ko-kr" } 
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    // src/pages
    PopUpForm,
    WebBannerForm,
    WebBannerVideoForm,
    StoreBannerForm,
    StoreBannerVideoForm,
    FaqForm,
    ChangePasswordForm,
    Login,
    ProductForm,
    SerialForm,
    GenuineForm,
    InvoiceForm,
    ChangeStatusForm,
    ChangeTransInfoForm,
    UserForm,
    // src/shared/components
    DialogMessage,
    DaumMap
  ]
})
export class AppModule {
}
