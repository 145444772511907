/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {UserService} from "../../../shared/services/user.service";
import {AuthService} from "../../../shared/services/auth.service";
import {AppService} from "../../../shared/services/app.service";
import {DaumMap} from "../../../shared/components/daum-map/daum-map";

@Component({
  selector: 'user-form',
  template: getTemplate('pages/user/user-form/user-form')
})
export class UserForm implements OnInit {

  config: MdDialogConfig = new MdDialogConfig();
  dialogDaumMapRef: MdDialogRef<DaumMap>;

  userId;
  user;

  deliveryInfo;
  address;

  password;
  passwordConfirm;

  formLabel;
  contentSize;

  constructor(public dialogRef: MdDialogRef<UserForm>,
              private mdDialog: MdDialog,
              private userService: UserService,
              private authService: AuthService,
              private appService: AppService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    this.user = {
      identifier: '',
      nickname: '',
      role: '사용자',
      privacyCheck: true,
      termsCheck: true,
      legalCheck: true
    };

    this.deliveryInfo = {
      addressInfo: {
        zipCode: '',
        address: '',
        detailAddress: ''
      },
      phone: null,
      name: ''
    };
    this.address = '';

    if(!this.userId){
      this.formLabel = '사용자 등록';
    } else {
      this.formLabel = '사용자 수정';
      this.loadItem();
    }
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid(){
    let regExpMail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    let regExpPassword = /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[*~`!^\-_+@\#$%&\=\(\)]).*$/;

    if(!this.userId){
      if(!this.password || this.password == '' || !regExpPassword.test(this.password))
        return false;
      else if(!this.passwordConfirm || this.passwordConfirm == '' || this.password != this.passwordConfirm)
        return false;
    }

    if(!this.user['identifier'] || this.user['identifier'] == '' || this.user['identifier'].length > 32 || !regExpMail.test(this.user['identifier']))
      return false;
    else if(!this.user['nickname'] || this.user['nickname'] == '' || (this.user['nickname'].length < 2 || this.user['nickname'] > 8))
      return false;
    else
      return true;
  }

  openDaumMapForm(){
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogDaumMapRef = this.mdDialog.open(DaumMap, this.config);

    this.dialogDaumMapRef.afterClosed()
      .subscribe((result) => {
        if(result && result.place){
          this.address = '';

          if(result.place.zipcode){
            this.address = '(' + result.place.zipcode + ') ';
            this.deliveryInfo['addressInfo'].zipCode = result.place.zipcode;
          }
          this.address += result.place.address;
          this.deliveryInfo['addressInfo'].address = result.place.address;
        }
      });
  }

  submit(){
    if(!this.userId)
      this.checkDuplicateUser();
    else
      this.update();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.userService.findOne({
      query: {
        _id: this.userId,
        isDeleted: true
      }
    })
      .subscribe(
        (userWrapper) => {
          this.user = userWrapper['user'];
          if(userWrapper['user'].deliveryInfo){
            this.deliveryInfo = userWrapper['user'].deliveryInfo;

            if(this.deliveryInfo.zipCode)
              this.address = '(' + this.deliveryInfo['addressInfo'].zipCode + ') ';
            this.address += this.deliveryInfo['addressInfo'].address;
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '사용자를 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  checkDuplicateUser(){
     if(this.isValid()){
       this.dialogService.loadingSubject.next('spinner');

       this.authService.checkIdentifier(this.user['identifier'])
         .flatMap(
           (result) => {
             if(!result['isAvailable']){
               throw new Error('already used mail');
             } else {
               return this.authService.checkNickname(this.user['nickname']);
             }
           }
         )
         .flatMap(
           (result) => {
             if(!result['isAvailable']){
               throw new Error('already used nickname');
             } else {
               return this.authService.checkNickname(this.user['nickname']);
             }
           }
         )
         .subscribe(
           () => {
             this.register();
           },
           (err) => {
             let subTitle = '';

             if(err.status){
               // server error handler
               switch (err.status) {
                 case 400:
                   subTitle = '입력한 정보를 확인해주세요.';
                   break;
                 case 500:
                   subTitle = '서버에러(ErrorName)' + err.name + ': ' + err.message;
                   break;
                 default:
                   subTitle = '잘못된 요청입니다.';
                   break;
               }
             } else {
               // client error handler
               if(err.message == 'already used mail')
                 subTitle = '이미 사용중인 이메일 입니다.';
               else if(err.message == 'already used nickname')
                 subTitle = '이미 사용중인 닉네임 입니다.';
             }
             this.dialogService.message("알림", subTitle);
             this.dialogService.loadingSubject.next('hide');
           }
         )
     } else {
       this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
     }
  }

  register(){
    this.user['password'] = this.password;
    this.user['deliveryInfo'] = this.deliveryInfo;

    this.authService.register(this.user)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (userWrapper) => {
          this.dialogRef.close();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '입력한 정보를 확인해주세요.';
              break;
            case 409:
              subTitle = '닉네임 또는 이메일이 이미 사용중입니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        });
  }

  update() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.user['deliveryInfo'] = this.deliveryInfo;

      this.userService.update(this.user)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 409:
                subTitle = '이미 사용중인 닉네임입니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  resetPasswordResetLimit() {
    this.dialogService.loadingSubject.next('spinner');

    this.userService.resetPasswordResetLimit(this.userId)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (data) => {
          this.dialogService.message("알림", "비밀번호 변경 요청 제한 리셋 완료");
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        });
  }

}
