/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */

// Browser Lib

// Angular
import { AbstractControl, AsyncValidatorFn } from "@angular/forms";

// Angular Third Party Lib

// Project Sources


let EMAIL_REGEXP: RegExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;

export function EmailAvailabilityValidator(authService, originalValue): AsyncValidatorFn {
  return (control: AbstractControl): Promise<any> => {
    return new Promise((resolve) => {

      if (originalValue === control.value) {
        resolve(null);
      }

      if (!EMAIL_REGEXP.test(control.value)) {
        resolve(null);
      }

      authService.checkEmail(control.value)
        .subscribe(data => {
            if (data.isAvailable) {
              resolve(null);
            } else {
              resolve({'emailTaken': true});
            }
          },
          () => {
            resolve({'emailTaken': true});
          });
    });
  }
}
