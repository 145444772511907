/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Angular Third Party Lib
import {FileUploader, Headers} from "../../../../lib/ng2-file-upload/components/file-upload/file-uploader.class";

// Project Sources
import {config} from "../../../../app/app.config";
import {getTemplate} from "../../../../shared/templates";
import {throttleable, resizeImage} from "../../../../shared/services/util";
import {AppService} from "../../../../shared/services/app.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";
import {BannerService} from "../../../../shared/services/banner.service";
import {FileService} from "../../../../shared/services/file.service";

@Component({
  selector: 'store-banner-form',
  template: getTemplate('pages/banner/store-banner/store-banner-form/store-banner-form')
})
export class StoreBannerForm implements OnInit {

  storeBannerId;
  storeBanner;
  cursorPointer = true;

  formLabel;
  contentSize;
  uploader: FileUploader;

  maxFiles = (amount) => {
    return {
      name: 'maxFiles',
      fn: function (file) {
        return this.queue.length < amount
      }
    }
  };

  imageOnly = {
    name: 'imageOnly',
    fn: function (file) {
      return file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg";
    }
  };

  resize = resizeImage;

  constructor(public dialogRef: MdDialogRef<StoreBannerForm>,
              private bannerService: BannerService,
              private appService: AppService,
              private fileService: FileService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    if (!this.storeBannerId){
      this.formLabel = '배너등록';
    } else {
      this.formLabel = '배너수정';
      this.loadItem();
    }

    this.storeBanner = {
      category: 'STORE',
      url: '',
      photos: [],
      imageVideo: 'image'
    };

    let headers = Array<Headers>();
    if (this.appService.user && this.appService.token) {
      headers.push({
        name: 'Authorization',
        value: 'Bearer ' + this.appService.token,
      });
    }
    this.uploader = new FileUploader(
      _.assign({
          filters: [
            {
              name: '.DS_Store',
              fn: (file) => {
                let filename = file.name.toLowerCase();
                return filename.indexOf('.ds_store') < 0;
              }
            }
          ]
        },
        {
          url: config.serverUrl + "/file",
          filters: [this.maxFiles(1), this.imageOnly],
          headers: headers
        })
    );

    this.uploader.completeAll.subscribe(() => {
      this.storeBanner.photo = this.uploader.queue[0].result.file;
    });
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    let urlRegExp = new RegExp('^(http|https)://');

    if(!this.storeBanner['category'] || this.storeBanner['category'].length <= 0) return false;
    else if(!this.storeBanner['url'] || this.storeBanner['url'].length <= 0 || !urlRegExp.test(this.storeBanner['url'])) return false;
    else if(!this.storeBanner['photo'] || this.storeBanner['photo'].length <= 0) return false;
    else return true;
  }

  upload(files) {
    this.uploader.clearQueue();
    if (files && files.length > 0) {
      this.uploader.addToQueue(files);
    }

    this.uploader.uploadAll();
  }

  mouseOverUpload() {
      this.storeBanner['url'] = this.storeBanner['url'].replace(/^\s+|\s+$/g, '');

      let urlRegExp = new RegExp('^(http|https)://');
      if(!urlRegExp.test(this.storeBanner['url'])){
          this.cursorPointer = false;
      }else if(this.storeBanner['url'] === 'http://' || this.storeBanner['url'] === 'https://'){
          this.cursorPointer = false;
      }
  }
  mouseOutUpload() {
      this.cursorPointer = true;
  }

  submit() {
    if(!this.storeBannerId)
      this.create();
    else
      this.update();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.bannerService
      .findOne({
        query: {_id: this.storeBannerId},
        populate: ['photo']
      })
      .subscribe(
        (bannerWrapper) => {
          this.storeBanner = bannerWrapper.banner;
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '웹배너를 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  create() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      let params: any = {
        query: { },
        limit: 1,
        sort: { displayOrder: -1 }
      };

      this.bannerService.find(params)
        .subscribe(
          (storeBannersWrapper) => {
            let displayOrder = 0;
            if (storeBannersWrapper['total'] > 0) {
              displayOrder = storeBannersWrapper['banners'][0].displayOrder;
            } else {
              displayOrder = 0;
            }

            this.storeBanner['displayOrder'] = displayOrder + 1;

            this.storeBanner.photo = this.storeBanner.photo._id;

            this.bannerService.create(this.storeBanner)
              .finally(() => {
                this.dialogService.loadingSubject.next('hide');
              })
              .subscribe(
                (data) => {
                  this.dialogRef.close();
                },
                (err) => {
                  let subTitle = '';
                  switch (err.status) {
                    case 500:
                      subTitle = '서버에러';
                      break;
                    default:
                      subTitle = '잘못된 요청입니다.';
                      break;
                  }
                  this.dialogService.message("알림", subTitle);
                });
          },
          (err) => {
          }
        );
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  update() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.storeBanner.photo = this.storeBanner.photo._id;

      this.bannerService.update(this.storeBanner)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  photoRemove(){
    this.dialogService.loadingSubject.next('spinner');

    this.fileService.remove(this.storeBanner.photo._id)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        () => {
          this.storeBanner.photo = null;
          this.uploader.clearQueue();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

}
