/**
 * Created by SSR on 03/25/2018
 * As part of NoseWork
 */

// Export Lib
declare var _;

// Angular
import {Component, HostListener, OnInit} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {TransactionService} from "../../../shared/services/transaction.service";

@Component({
  selector: 'change-trans-info-form',
  template: getTemplate('pages/invoice/change-trans-info-form/change-trans-info-form')
})
export class ChangeTransInfoForm implements OnInit{

  invoiceId;
  invoice;

  companyValue;
  otherName;
  transNumber;
  companyList;
  transInfo;

  contentSize;

  constructor(public dialogRef: MdDialogRef<ChangeTransInfoForm>,
              private dialogService: DialogService,
              private transactionService: TransactionService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    this.companyList = [
      {name: 'CJ대한통운', value: '0'},
      {name: '우체국택배', value: '1'},
      {name: '한진택배', value: '2'},
      {name: '롯데택배', value: '3'},
      {name: '드림택배', value: '4'},
      {name: 'CVSnet 편의점 택배', value: '5'},
      {name: 'CU 편의점 택배', value: '6'},
      {name: '수동입력', value: '7'}
    ];
    this.transInfo = {
      companyName:"",
      transNumber:0
    }
    this.recalcSize();
    this.loadItem();
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    if(!this.companyValue){
      return false;
    } else if(this.companyValue == '7' && !this.otherName){
      return false;
    } else if(!this.transNumber){
      return false;
    }
    return true;
  }

  checkAvailableChangeStatus(){
    switch(this.invoice.status){
      case 'PAYMENT_FAILED':
        return false;
      case 'ORDER_CANCELLED':
        return false;
      case 'REFUND_DONE':
        return false;
    }
    if(this.invoice.paymentType !='bank' && this.invoice.status == 'PAYMENT_PENDING')
      return false;

    return true;
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.transactionService.findOneInvoice({
      query: {_id: this.invoiceId},
      populate: ['owner']
    })
      .subscribe(
        (invoiceWrapper) => {
          this.invoice = invoiceWrapper['invoice'];

          if(!this.checkAvailableChangeStatus()){
            this.dialogService.message("알림", '실패 또는 취소된 주문은 운송정보를 변경할 수 없습니다.');
            this.dismiss();
          }

        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  submit(){
    if(this.isValid())
    {
      if(this.companyValue == '7')
        this.transInfo.companyName = this.otherName;
      else{
        _.forEach(this.companyList,(value, key) => {
          if(key == this.companyValue){
            this.transInfo.companyName = value.name;
          }
        });
      }
      this.transInfo.transNumber = this.transNumber;
      this.dialogService.loadingSubject.next('spinner');

      this.transactionService.changeTransInfo({invoiceId : this.invoiceId, transInfo: this.transInfo})
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (invoiceWrapper) => {
            this.dialogService.message("알림", '변경이 완료되었습니다.');
            this.dismiss();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 400:
                subTitle = '주문 내역을 찾을 수 없습니다.';
                break;
              case 422:
                subTitle = '고객센터 또는 PG사로 문의가 필요합니다.';
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
    } else{
      this.dialogService.message("알림", "입력정보를 정확히 입력하세요.");
    }
  }

}