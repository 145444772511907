/**
 * Created by sungwookim on 10/25/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by sungwookim <ksw1652@gmail.com>, 10/25/2016
 *
 */

// Export Lib
declare var moment;

// Angular
import {Component, OnInit, Input, EventEmitter, Output, SimpleChange, OnChanges} from '@angular/core';

// Project Sources
import {getTemplate} from "../../../shared/templates";

//Interface
interface CalendarDate {
  day: number;
  month: number;
  year: number;
  disabled: boolean;
  today: boolean;
  selected: boolean;
}

@Component({
  selector: 'datepicker',
  template: getTemplate('shared/components/datepicker/datepicker.component')
})
export class Datepicker implements OnInit {
  @Input() btnUrl: string;
  @Input() opened: boolean;
  @Input() showDate: any;
  @Input() checkDisable: boolean;

  selectedDateValue: any = new Date();
  @Output() selectedDateChange = new EventEmitter();

  @Input()
  get selectedDate() {
    return this.selectedDateValue;
  }

  set selectedDate(val) {
    this.selectedDateValue = val;
    this.showDate = moment(val);
    this.generateCalendar();
    this.selectedDateChange.emit(this.selectedDateValue);
  }

  days: CalendarDate[] = [];

  constructor() {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.opened = false;
    this.generateCalendar();
  }

  /*****************************
   *        util functions
   *****************************/

  generateCalendar() {
    let today = moment();
    let date = moment(this.showDate);
    let month = date.month();
    let year = date.year();
    let n: number = 1;
    let firstWeekDay: number = date.date(2).day();

    if (firstWeekDay !== 1) {
      n -= (firstWeekDay + 6) % 7;
    }

    let lastDate = 7 - (moment(this.showDate).endOf('month').day() + 1);
    let disableCheck = false;

    this.days = [];
    for (let i = n; i <= (date.endOf('month').date() + lastDate); i += 1) {
      let currentDate = moment().set('year', year).set('month', month).set('date', i);
      let todayCheck = (currentDate.isSame(today, 'day') && currentDate.isSame(today, 'month'));
      let selectCheck = (moment(this.selectedDateValue).isSame(currentDate, 'day') &&
      moment(this.selectedDateValue).isSame(currentDate, 'month') &&
      moment(this.selectedDateValue).isSame(currentDate, 'year'));
      if (this.checkDisable) {
        disableCheck = (currentDate.isAfter(today, 'date'));
      }

      this.days.push({
        day: currentDate.date(),
        month: currentDate.month(),
        year: currentDate.year(),
        disabled: disableCheck,
        today: todayCheck,
        selected: selectCheck
      });
    }
  }

  selectDate(e: MouseEvent, i: number) {
    e.preventDefault();

    let date: CalendarDate = this.days[i];
    this.selectedDate = moment().set('year', date.year).set('month', date.month).set('date', date.day);

    this.close();
  }

  prevMonth() {
    this.showDate = moment(this.showDate.subtract(1, 'month'));
    this.generateCalendar()
  }

  nextMonth() {
    this.showDate = moment(this.showDate.add(1, 'month'));
    this.generateCalendar();
  }

  prevYear() {
    this.showDate.subtract(1, 'year');
    this.generateCalendar();
  }

  nextYear() {
    this.showDate.add(1, 'year');
    this.generateCalendar();
  }

  toggle() {
    this.opened = !this.opened;
  }

  open() {
    this.opened = true;
  }

  close() {
    this.opened = false;
  }

}