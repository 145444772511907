/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit} from '@angular/core';

// Angular Third Party Lib
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {resizeImage} from "../../shared/services/util";
import {AppService} from "../../shared/services/app.service";
import {TransactionService} from "../../shared/services/transaction.service";
import {UserService} from "../../shared/services/user.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";
import {InvoiceForm} from "./invoice-form/invoice-form";

@Component({
  selector: 'invoice',
  template: getTemplate('pages/invoice/invoice')
})

export class Invoice implements OnInit {

  appEventDisposor;

  options = {
    rows: [],
    selected: null,
    headerHeight: "80px",
    footerHeight: "100px",
    rowHeight: "80px",
    bodyHeight: "640px",
    limit: 8,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  dialogInvoiceRef: MdDialogRef<InvoiceForm>;
  config: MdDialogConfig = new MdDialogConfig();

  resize = resizeImage;


  //
  searchBtnFlag = false;
  //
  searchKeyword = "";
  statusList = [];
  filteredInvoiceList = [];
  selectedStatus = {name: '전체', value: ''};
  startDate = '';
  endDate = '';

  constructor(private mdDialog: MdDialog,
              private appService: AppService,
              private transactionService: TransactionService,
              private userService: UserService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.searchBtnFlag = false;
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.loadList({page: this.options.page});
    
    this.statusList = [
      {name: '전체', value: ''},
      {name: '결제대기', value: 'PAYMENT_PENDING'},
      {name: '결제실패', value: 'PAYMENT_FAILED'},
      {name: '결제완료', value: 'PAYMENT_DONE'},
      {name: '주문취소', value: 'ORDER_CANCELLED'},
      {name: '배송준비', value: 'DELIVERY_PREPARE'},
      {name: '배송중', value: 'DELIVERING'},
      {name: '배송완료', value: 'DELIVERED'},
      {name: '교환요청', value: 'EXCHANGE_REQUESTED'},
      {name: '교환완료', value: 'EXCHANGE_DONE'},
      {name: '환불요청', value: 'REFUND_REQUESTED'},
      {name: '환불완료', value: 'REFUND_DONE'},
      {name: '주문완료', value: 'ORDER_DONE'}
    ];
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  /*****************************
   *        util functions
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.loadList({page: this.options.page});
        break;
    }
  }

  openInvoiceFormModel(invoice?) {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogInvoiceRef = this.mdDialog.open(InvoiceForm, this.config);
    if (invoice)
      this.dialogInvoiceRef.componentInstance.invoiceId = invoice._id;

    this.dialogInvoiceRef.afterClosed()
      .subscribe((result) => {
        //this.loadList({page: this.options.page});
      });
  }

  /*****************************
   *       helper functions
   *****************************/

  searchUser() {
    this.searchBtnFlag = true;
    this.dialogService.loadingSubject.next('spinner');
    var or = [];
    var orObj = {};

    if(isNaN(this.searchKeyword)){
      or.push({identifier: {$regex: ".*" + this.searchKeyword + ".*"}});
      or.push({nickname: {$regex: ".*" + this.searchKeyword + ".*"}});
      orObj = {$or : or};
    } else {
      orObj = {};
    }
    this.userService.getUserList({query: orObj, limit: -101})
      .subscribe(
        (userWrapper) => {
          if(userWrapper['users'] && userWrapper['users'].length > 0){
            let userIds = _.uniq(_.map(userWrapper['users'], '_id'));
            this.loadList({
              page: 1,
              userIds: userIds
            })
          } else {
            this.dialogService.message("알림", "사용자를 찾을 수 없습니다.");
            this.dialogService.loadingSubject.next('hide');
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
          this.dialogService.loadingSubject.next('hide');
        }
      )
  }

  getDate(date) {
    var dt = new Date(date);
    var year = dt.getFullYear();
    var month = dt.getMonth() + 1;
    var day = dt.getDate();
    dt = new Date(year + "-" + month + "-" + day + " 00:00:00");
    return dt;
  }

  downloadExcel() {
    let params: any = {      
      query:{
        isDeleted: false
      },
      sort: {lastUpdatedAt: -1}
    };
    if(this.startDate != ''){
      params.query['createdAt'] = {}
      params.query['createdAt']['$gte'] = this.startDate;
    }
    if(this.endDate != ''){
      if(params.query['createdAt'])
        params.query['createdAt']['$lte'] = this.endDate;
      else{
        params.query['createdAt'] = {$lte: this.endDate};
      }
    }
    params.query.isDeleted = false;
    var or = [];
    if (this.searchKeyword)
    {
      or.push({identifier: {$regex: ".*" + this.searchKeyword + ".*"}});
      or.push({nickname: {$regex: ".*" + this.searchKeyword + ".*"}});

      if (!isNaN(this.searchKeyword)){
        or.push({'deliveryInfo.phone': this.searchKeyword});
      }
      params['$or'] = or;
    }
    if(this.selectedStatus.value != '')
      params.status = this.selectedStatus.value;

    this.transactionService.downloadExcel(params);
  }

  loadList(event) {
      this.options.page = event.page;
      let skip = (this.options.page - 1) * this.options.limit;
      let params: any = {
        query: {},
        limit: this.options.limit,
        skip: skip,
        sort: {lastUpdatedAt: -1},
        populate: ['owner'],
        status : '',
        $or : {}
      };
      if(this.searchKeyword != "" && !isNaN(this.searchKeyword)){
        params['$or'] = this.searchKeyword;
      }
      if(event.userIds && event.userIds.length > 0)
        params.query['owner'] = {$in: event.userIds};

      if (this.startDate != '') {
        params.query['createdAt'] = {}
        params.query['createdAt']['$gte'] = this.startDate;
      }
      if(this.endDate != ''){
        if(params.query['createdAt'])
          params.query['createdAt']['$lte'] = this.endDate;
        else{
          params.query['createdAt'] = {$lte: this.endDate};
        }
      }
      
      params.status = this.selectedStatus.value;
      
      this.filteredInvoiceList = [];
      this.transactionService.findInvoice(params)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (invoicesWrapper) => {
            _.forEach(invoicesWrapper['invoices'], (invoice, index) => {
              
              var bl = true;
              if (this.selectedStatus.value != '' && invoice.status != this.selectedStatus.value)
                bl = false;      
            
              var time = new Date(invoice.createdAt);
              var utc = time.getTime() + (time.getTimezoneOffset() *60000);
              var offset = 9;
              var seoulTime = new Date(utc + (3600000 * offset));
              
              var invoiceTime = seoulTime.getFullYear() + "년" + (seoulTime.getMonth() + 1) + "월" + seoulTime.getDate() + "일" + seoulTime.getHours() + "시" + seoulTime.getMinutes() + "분";

              invoice.createdTime = invoiceTime;
              var createDate = this.getDate(invoice.createdAt);

              if ((this.startDate != '' && createDate < this.startDate) 
                  || (this.endDate != '' && createDate > this.endDate))
                bl = false;
                    
              if (bl) {
                invoice['no'] = skip + index + 1;
                invoice['status_history'] = [];
                
                if (invoice.orderCancelledAt) {
                  //ORDER_CANCELLED 
                  invoice['status_history'].push({name : '주문취소', date : invoice.orderCancelledAt});
                }

                if (invoice.deliveryPrepareAt) {
                  //DELIVERY_PREPARE 
                  invoice['status_history'].push({name : '배송준비', date : invoice.deliveryPrepareAt});
                }

                if (invoice.deliveringAt) {
                  //DELIVERING 
                  invoice['status_history'].push({name : '배송중', date : invoice.deliveringAt});
                }

                if (invoice.deliveredAt) {
                  //DELIVERED 
                  invoice['status_history'].push({name : '배송완료', date : invoice.deliveredAt});
                }

                if (invoice.exchangeRequestedAt) {
                  //EXCHANGE_REQUESTED 
                  invoice['status_history'].push({name : '교환요청', date : invoice.exchangeRequestedAt});
                }

                if (invoice.exchangeDoneAt) {
                  //EXCHANGE_DONE 
                  invoice['status_history'].push({name : '교환완료', date : invoice.exchangeDoneAt});
                }

                if (invoice.refundRequestedAt) {
                  //REFUND_REQUESTED 
                  invoice['status_history'].push({name : '환불요청', date : invoice.refundRequestedAt});
                }

                if (invoice.refundDoneAt) {
                  //REFUND_DONE 
                  invoice['status_history'].push({name : '환불완료', date : invoice.refundDoneAt});
                }

                if (invoice.orderDoneAt) {
                  //ORDER_DONE 
                  invoice['status_history'].push({name : '주문완료', date : invoice.orderDoneAt});
                }

                if (invoice['status_history'].length > 0) {
                  
                  for (let i = 0; i < invoice['status_history'].length; i++) 
                  {
                    var latest = invoice['status_history'][i];
                    for (let j = i + 1; j < invoice['status_history'].length; j++)
                    {
                      var compare = invoice['status_history'][j];
                      if (latest.date < compare.date)
                      {                      
                        invoice['status_history'][j] = latest;
                        invoice['status_history'][i] = compare;
                        latest = compare;
                      }
                    }
                  }
                }

                switch(invoice.status){
                  case 'PAYMENT_PENDING':
                    invoice['translateStatus'] = '결제대기';
                    break;
                  case 'PAYMENT_FAILED':
                    invoice['translateStatus'] = '결제실패';
                    break;
                  case 'PAYMENT_DONE':
                    invoice['translateStatus'] = '결제완료';
                    break;
                  case 'ORDER_CANCELLED':
                    invoice['translateStatus'] = '주문취소';
                    break;
                  case 'DELIVERY_PREPARE':
                    invoice['translateStatus'] = '배송준비';
                    break;
                  case 'DELIVERING':
                    invoice['translateStatus'] = '배송중';
                    break;
                  case 'DELIVERED':
                    invoice['translateStatus'] = '배송완료';
                    break;
                  case 'EXCHANGE_REQUESTED':
                    invoice['translateStatus'] = '교환요청';
                    break;
                  case 'EXCHANGE_DONE':
                    invoice['translateStatus'] = '교환완료';
                    break;
                  case 'REFUND_REQUESTED':
                    invoice['translateStatus'] = '환불요청';
                    break;
                  case 'REFUND_DONE':
                    invoice['translateStatus'] = '환불완료';
                    break;
                  case 'ORDER_DONE':
                    invoice['translateStatus'] = '주문완료';
                    break;
                }
                switch(invoice.paymentType){
                  case 'card':
                    invoice['translatePaymentType'] = '신용카드';
                    break;
                  case 'trans':
                    invoice['translatePaymentType'] = '실시간 계좌이체';
                    break;
                  case 'bank':
                    invoice['translatePaymentType'] = '무통장 입금';
                    break;
                }
                this.filteredInvoiceList.push(invoice);
              }
            });

            this.options['rows'] = [];
            var end = skip + this.options.limit;
            
            for (var i = 0; i < invoicesWrapper['invoices'].length; i++) {
              this.filteredInvoiceList[i]['no'] = skip + 1 + i;
              this.options['rows'].push(this.filteredInvoiceList[i]);
            }

            this.options['count'] = invoicesWrapper['total'];
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
      }

}
