/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {TransactionService} from "../../../shared/services/transaction.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {ChangeStatusForm} from "../change-status-form/change-status-form";
import { ChangeTransInfoForm } from '../change-trans-info-form/change-trans-info-form';

@Component({
  selector: 'invoice-form',
  template: getTemplate('pages/invoice/invoice-form/invoice-form')
})
export class InvoiceForm implements OnInit {

  invoiceId;
  invoice;

  optionList = [];
  translateStatus;
  translatePaymentType;

  dialogChangeStatusRef: MdDialogRef<ChangeStatusForm>;
  dialogChangeTransInfoRef: MdDialogRef<ChangeTransInfoForm>;
  config: MdDialogConfig = new MdDialogConfig();

  contentSize;

  constructor(public dialogRef: MdDialogRef<InvoiceForm>,
              private mdDialog: MdDialog,
              private dialogService: DialogService,
              private transactionService: TransactionService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();
    this.loadItem();
    this.optionList = [];
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    return true;
  }

  openChangeStatusFormModel() {
    if(this.checkAvailableChangeStatus()){
      this.config['width'] = '900px';
      this.config.disableClose = true;

      this.dialogChangeStatusRef = this.mdDialog.open(ChangeStatusForm, this.config);
      this.dialogChangeStatusRef.componentInstance.invoiceId = this.invoice._id;

      this.dialogChangeStatusRef.afterClosed()
        .subscribe((result) => {
          this.loadItem();
        });
    } else {
      this.dialogService.message("알림", '실패 또는 취소된 주문은 상태를 변경할 수 없습니다.');
    }
  }
  
  openChangeTransInfoFormModel() {
    if(this.checkAvailableChangeStatus()){
      this.config['width'] = '900px';
      this.config.disableClose = true;

      this.dialogChangeTransInfoRef = this.mdDialog.open(ChangeTransInfoForm, this.config);
      this.dialogChangeTransInfoRef.componentInstance.invoiceId = this.invoice._id;

      this.dialogChangeTransInfoRef.afterClosed()
        .subscribe((result) => {
          this.loadItem();
        });
    } else {
      this.dialogService.message("알림", '실패 또는 취소된 주문은 상태를 변경할 수 없습니다.');
    }
  }

  checkAvailableChangeStatus(){
    switch(this.invoice.status){
      case 'PAYMENT_FAILED':
        return false;
      case 'ORDER_CANCELLED':
        return false;
      case 'REFUND_DONE':
        return false;
    }
    if(this.invoice.paymentType !='bank' && this.invoice.status == 'PAYMENT_PENDING')
      return false;

    return true;
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.transactionService.findOneInvoice({
      query: {_id: this.invoiceId},
      populate: ['owner']
    })
      .subscribe(
        (invoiceWrapper) => {
          this.invoice = invoiceWrapper['invoice'];

          if (this.invoice.deliveryInfo) {
            if (!this.invoice.deliveryInfo.name) this.invoice.deliveryInfo.name = "";
          }
          
          _.forEach(this.invoice.itemList, (item) => {
            item.optionList = [];

            if (item.product.salePrice)
              item['totalAmount'] = item.product.salePrice * item.quantity;
            else
              item['totalAmount'] = item.product.price * item.quantity;
          });
          
          // this.invoice.totalQuantity 는 db의 것을 그대로 이용 2018.06.14
          // this.invoice.totalQuantity = 0;
          // for (var i =0 ; i < this.invoice.itemList.length; i++) {
          //   this.invoice.totalQuantity += this.invoice.itemList[i].quantity;
          // }

          for (var i = 0; i < this.invoice.itemList.length; i++) {
            var item = this.invoice.itemList[i];

            var optionOne = [];

            if (item.selectedOption != null && item.selectedOption.length > 0) {
              for (var j = 0; j < item.selectedOption.length; j++) {
                if (item.selectedOption[j].property.length > 0) {
                  for (var k = 0; k < item.selectedOption[j].property.length; k++) {
                    var optionName = "";
                    if (item.selectedOption[j].name && item.selectedOption[j].name != 'undefined')
                      optionName = item.selectedOption[j].name;

                    if (item.selectedOption[j].property[k].name && item.selectedOption[j].property[k].name != 'undefined') {
                      if (optionName == "") {
                        optionName = item.selectedOption[j].property[k].name;
                      } else {
                        optionName = optionName + "-" + item.selectedOption[j].property[k].name;
                      }
                    }

                    var optionObj = {
                      name: optionName,
                      price: item.selectedOption[j].property[k].price,
                      quantity: item.selectedOption[j].property[k].quantity
                    };
                    optionOne.push(optionObj);
                  }
                }
              }

              if (optionOne.length > 0) {
                if(item.product.attribute && item.product.attribute.length > 0)// 필수 옵션이 있는 경우에만 totalAmount 다시 계산  2018.06.14
                  item['totalAmount'] = 0;
                // this.invoice.totalQuantity = 0;
                for (var k = 0; k < optionOne.length; k++) {
                  item['totalAmount'] += optionOne[k].price * optionOne[k].quantity;
                  // this.invoice.totalQuantity += optionOne[k].quantity;
                }
              }

              item.optionList = optionOne;

              this.optionList.push(optionOne);
            } else {
              // 옵션이 없는 경우에는 빈옵션을 추가해준다. 20180615
              item.optionList = optionOne;
              this.optionList.push(optionOne);              
            }
          }

          switch (this.invoice.status) {
            case 'PAYMENT_PENDING':
              this.translateStatus = '결제대기';
              break;
            case 'PAYMENT_FAILED':
              this.translateStatus = '결제실패';
              break;
            case 'PAYMENT_DONE':
              this.translateStatus = '결제완료';
              break;
            case 'ORDER_CANCELLED':
              this.translateStatus = '주문취소';
              break;
            case 'DELIVERY_PREPARE':
              this.translateStatus = '배송준비';
              break;
            case 'DELIVERING':
              this.translateStatus = '배송중';
              break;
            case 'DELIVERED':
              this.translateStatus = '배송완료';
              break;
            case 'EXCHANGE_REQUESTED':
              this.translateStatus = '교환요청';
              break;
            case 'EXCHANGE_DONE':
              this.translateStatus = '교환완료';
              break;
            case 'REFUND_REQUESTED':
              this.translateStatus = '환불요청';
              break;
            case 'REFUND_DONE':
              this.translateStatus = '환불완료';
              break;
            case 'ORDER_DONE':
              this.translateStatus = '주문완료';
              break;
          }
          switch(this.invoice.paymentType){
            case 'card':
              this.translatePaymentType = '신용카드';
              break;
            case 'trans':
              this.translatePaymentType = '실시간 계좌이체';
              break;
            case 'bank':
              this.translatePaymentType = '무통장 입금';
              break;
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

}
