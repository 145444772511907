/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Angular
import {Injectable} from "@angular/core";

// Project Source
import {DialogService} from "../components/dialog-message/dialog-message.service";

@Injectable()
export class FileValidateService{

  constructor(private dialogService: DialogService){}

  /**
   * 업로드 파일 갯수 확인
   * min default: 1, max default: 1
   * @return boolean
   */
  uploadPhotoValidate(files: any, photoLength?: any, min?: number, max?: number): any{

    let minCount = min ? min : 1;
    let maxCount = max ? max: 1;

    if(files.length >= minCount){
      let totalLength = photoLength ? photoLength : 0;
      totalLength += files.length;
      if(totalLength <= maxCount){
        if (!this.fileExtensionValidate(files)) {
          return this.dialogService.message("알림", '이미지 파일만 업로드 할 수 있습니다. .png, .jpg, .jpeg, .gif, .bmp 확장자 파일만 업로드해 주세요.');
        } else if (!this.fileSizeValidate(files)) {
          return this.dialogService.message("알림", '이미지 용량의 최대 사이즈는 4MB입니다.');
        } else {
          return true;
        }
      } else {
        return this.dialogService.message("알림", '이미지는 최대 ' + maxCount + '장 까지만 등록가능합니다.');
      }
    } else {
      return this.dialogService.message("알림", '이미지는 최소 ' + minCount + '장 까지만 등록가능합니다.');
    }
  }

  uploadPdfValidate(files: any): any {
    if(files.length > 1) {
      return this.dialogService.message("알림", '업체 소개 파일은 1장만 업로드 할 수 있습니다.');
    } else {
      if(!this.filePdfValidate(files)) {
        return this.dialogService.message("알림", '업체 소개 파일은 .pdf 확장자 파일만 업로드해 주세요.');
      } else if(!this.fileSizeValidate(files, 1048576 * 20)) {
        return this.dialogService.message("알림", '업체 소개 파일의 최대 용량은 20MB입니다.');
      } else {
        return true;
      }
    }
  }

  /**
   * 업로드 파일 이미지 여부 확인
   * @return boolean
   */
  fileExtensionValidate(files: any): boolean {
    let i;
    for (i = 0; i < files.length; i++) {
      if (!files[i]._file.name.match(/\.(jpe?g|png|gif|bmp)$/i)) {
        break;
      }
    }

    if (i == files.length){
      return true;
    } else {
      return false;
    }
  }

  filePdfValidate(files: any): boolean {
    let i;
    for (i = 0; i < files.length; i++) {
      if (!files[i]._file.name.match(/\.(pdf)$/i)) {
        break;
      }
    }

    if (i == files.length){
      return true;
    } else {
      return false;
    }
  }

  /**
   * 업로드 파일 사이즈 확인
   * Default limit 4MB
   * @return boolean
   */
  fileSizeValidate(files: any, size?: number): boolean {
    let maxSize = size ? size : 1048576 * 4;

    let i;
    for (i = 0; i < files.length; i++) {
      if (files[i]._file.size >= maxSize) {
        break;
      }
    }

    if (i == files.length){
      return true;
    } else{
      return false;
    }
  }

}