/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Angular Third Party Lib
import {FileUploader, Headers} from "../../../lib/ng2-file-upload/components/file-upload/file-uploader.class";

// Project Sources
import {config} from "../../../app/app.config";
import {getTemplate} from "../../../shared/templates";
import {throttleable, resizeImage} from "../../../shared/services/util";
import {AppService} from "../../../shared/services/app.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {PopUpService} from "../../../shared/services/popup.service";
import {FileService} from "../../../shared/services/file.service";

@Component({
  selector: 'popup-form',
  template: getTemplate('pages/popup/popup-form/popup-form')
})
export class PopUpForm implements OnInit {

  popupId;
  popup;
  cursorPointer = true;

  detailPhoto;
  formLabel;
  contentSize;
  uploader: FileUploader;

  maxFiles = (amount) => {
    return {
      name: 'maxFiles',
      fn: function (file) {
        return this.queue.length < amount
      }
    }
  };

  imageOnly = {
    name: 'imageOnly',
    fn: function (file) {
      return file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg";
    }
  };

  resize = resizeImage;

  constructor(public dialogRef: MdDialogRef<PopUpForm>,
              private popupService: PopUpService,
              private appService: AppService,
              private fileService: FileService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    this.popup = {
      url: '',
      photo: [],
      showPopUp: 0,
      image:0,
      show : 0,
      _id:0
    }

    if (!this.popupId) {
      this.formLabel = '팝업 등록';
    } else {
      this.formLabel = '팝업 수정';
      this.loadItem();
    }

    
    this.detailPhoto = null;
    let headers = Array<Headers>();
    if (this.appService.user && this.appService.token) {
      headers.push({
        name: 'Authorization',
        value: 'Bearer ' + this.appService.token,
      });
    }
    this.uploader = new FileUploader(
      _.assign({
          filters: [
            {
              name: '.DS_Store',
              fn: (file) => {
                let filename = file.name.toLowerCase();
                return filename.indexOf('.ds_store') < 0;
              }
            }
          ]
        },
        {
          url: config.serverUrl + "/file",
          filters: [this.maxFiles(1), this.imageOnly],
          headers: headers
        })
    );

    this.uploader.completeAll.subscribe(() => {
      this.detailPhoto = this.uploader.queue[0].result.file;
    });
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    let urlRegExp = new RegExp('^(http|https)://');
    if (!urlRegExp.test(this.popup['url']) && this.popup['url'].length > 0) return false;
    else if (this.popup.image == "0" && !this.detailPhoto) return false;
    else return true;
  }

  upload(files) {
    this.uploader.clearQueue();
    if (files && files.length > 0) {
      this.uploader.addToQueue(files);
    }
    this.uploader.uploadAll();
  }

  mouseOverUpload() {
      this.popup['url'] = this.popup['url'].replace(/^\s+|\s+$/g, '');

      let urlRegExp = new RegExp('^(http|https)://');
      if (!urlRegExp.test(this.popup['url'])) {
          this.cursorPointer = false;
      } else if(this.popup['url'] === 'http://' || this.popup['url'] === 'https://') {
          this.cursorPointer = false;
      }
  }

  mouseOutUpload() {
      this.cursorPointer = true;
  }

  // made by mangus
  showIndex = 0;
  madePopup() {
    if (!this.popupId) {
      this.create();
    } else {
      this.update();
    }
  }

  //
  submit() {
    let params: any = {
      sort: {_id: -1},
      populate: ['photo']
    };
    this.popupService.find(params)
      .subscribe(
        (PopUpsWrapper) => {
          this.showIndex = 0;
          for (var i = 0; i < PopUpsWrapper['PopUps'].length; i++) {
            if (PopUpsWrapper['PopUps'][i].show) {
              this.showIndex++;
            }
          }

          if (this.popup.show == 1) {
              this.madePopup();
          } else {
            if (this.showIndex >= 5 && !this.popupId) {
              this.dialogService.message("알림", "최대 5개 등록가능합니다.");
              this.dialogService.loadingSubject.next('hide');
            } else {
              this.madePopup();
            }
          }
          
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.popupService
      .findOne({
        query: {_id: this.popupId},
        populate: ['photo']
      })
      .subscribe(
        (PopUpWrapper) => {
          if(!PopUpWrapper.PopUp.show)
            PopUpWrapper.PopUp.show = 1;
          else
            PopUpWrapper.PopUp.show = 0;

          if(!PopUpWrapper.PopUp.imageShow)
            PopUpWrapper.PopUp.image = 1;
          else
            PopUpWrapper.PopUp.image = 0;
            
          this.popup = PopUpWrapper.PopUp;
          if(PopUpWrapper.PopUp.imageShow)
            this.detailPhoto = PopUpWrapper.PopUp.photo[0];
          
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '웹배너를 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  create() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');
      
      
      if(this.popup.show == 0)
        this.popup.show = true;
      else if(this.popup.show == 1)
        this.popup.show = false;

      if(this.popup.image == 0){
        this.popup.photo.push(this.detailPhoto);
      }
      this.popupService.create(this.popup)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  update() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      if(this.popup.show == 0){
        this.popup.show = true;
      }
      else if(this.popup.show == 1){
        this.popup.show = false;
      }

      this.popup.photo = [];
      if (this.popup.image == 0) {
        this.popup.photo.push(this.detailPhoto);
      }
      
      this.popupService.update(this.popup)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  photoRemove(removePhoto){
    this.dialogService.loadingSubject.next('spinner');
    if(removePhoto == null)
      return;
    this.fileService.remove(removePhoto._id)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        () => {
          this.detailPhoto = null;
          this.uploader.clearQueue();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

}
