/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Browser Lib
import {Observable} from "rxjs";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party Lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class MailService{

  private serverUrl = config.serverUrl + '/mail';

  constructor(private http: InterceptableHttp){}

  sendToClients(queryParams: any): Observable<any>{
    let url = this.serverUrl + '/sendToClients';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

}