/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, HostListener, OnInit} from "@angular/core";
import {MdDialogRef, MdDialog} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {throttleable} from "../../shared/services/util";
import {AppService} from "../../shared/services/app.service";
import {AuthService} from "../../shared/services/auth.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'login',
  template: getTemplate('pages/login/login')
})
export class Login implements OnInit {

  loginInfo;
  validationText;

  constructor(public dialog: MdDialog,
              public dialogRef: MdDialogRef<Login>,
              private appService: AppService,
              private authService: AuthService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.loginInfo = {
      identifier: '',
      password: ''
    };
    this.validationText = '';
  }

  /*****************************
   *        util functions
   *****************************/

  isValidLogin() {
    let regExpMail = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    let regExpPassword = /^.*(?=^.{8,12}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[*~`!^\-_+@\#$%&\=\(\)]).*$/;

    if (!this.loginInfo['identifier'] || this.loginInfo['identifier'] == '' || this.loginInfo['identifier'].length > 32 || !regExpMail.test(this.loginInfo['identifier'])){
      this.validationText = '이메일을 확인해주세요.';
      return false;
    } else if (!this.loginInfo['password'] || this.loginInfo['password ']== '') {
      this.validationText = '비밀번호를 확인해주세요.';
      return false;
    } else if(!regExpPassword.test(this.loginInfo['password'])){
      this.validationText = '영문, 숫자, 특수문자 포함 8자 ~ 12자 이내로 입력해주세요.';
      return false;
    } else {
      return true;
    }
  }

  /*****************************
   *       helper functions
   *****************************/

  login() {
    if(this.isValidLogin()){
      this.dialogService.loadingSubject.next('spinner');

      this.authService.login(this.loginInfo['identifier'], this.loginInfo['password'])
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (userWrapper) => {
            if(userWrapper['user']){
              if(userWrapper['user'].role != '관리자') {
                this.dialogService.message("알림", "관리자만 접속 할수 있는 공간 입니다.");
              } else if(userWrapper['user'].isDeleted) {
                this.dialogService.message("알림", "비활성화된 계정입니다. 다른 관리자에게 요청해주세요.");
              } else {
                this.appService.user = userWrapper['user'];
                this.appService.token = userWrapper['token'];
                this.appService.sendEvent('login');
                // this.dialogRef.close('login');
                this.dialogRef.close();
              }
            } else if(!userWrapper['user']){
              this.dialogService.message("알림", "등록되지 않은 사용자입니다.");
            }
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 400:
                subTitle = "아이디 또는 비밀번호를 확인해주세요.";
                break;
              case 401:
                subTitle = '등록되지 않은 사용자입니다.';
                if(err._body){
                  let message = JSON.parse(err._body).message;
                  if(message == 'WrongPassword')
                    subTitle = '비밀번호를 확인해주세요.';
                  else if(message == 'UnActivateUser')
                    subTitle = '비활성화된 계정입니다.';
                }
                break;
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", this.validationText);
    }
  }

}
