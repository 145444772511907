/**
 * Created by Andy Shin Yoon Yong on 14/04/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Andy Shin Yoon Yong - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andy Shin Yoon Yong <developer@applicat.co.kr>, 12/07/2017
 *
 */

import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Pipe({name: 'full_youtube_url'})
export class FullYoutubeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string): any {
    if (!value) return value;
    
    let video_id = value.substring(value.indexOf('/watch?v=') + 9);
    let pos = video_id.indexOf('&');
    if (pos >= 0) {
      video_id = video_id.substring(0, pos);
    }
    let full_youtue_url = this.sanitizer.bypassSecurityTrustResourceUrl("http://www.youtube.com/embed/" + video_id);

    return full_youtue_url;
  }
}