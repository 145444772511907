/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit} from '@angular/core';

// Angular Third Party Lib
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {resizeImage} from "../../../shared/services/util";
import {AppService} from "../../../shared/services/app.service";
import {BannerService} from "../../../shared/services/banner.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {WebBannerForm} from "./web-banner-form/web-banner-form";
import {WebBannerVideoForm} from "./web-banner-form/web-banner-video-form";

@Component({
  selector: 'web-banner',
  template: getTemplate('pages/banner/web-banner/web-banner')
})

export class WebBanner implements OnInit {

  appEventDisposor;
  options = {
    rows: [],
    selected: null,
    headerHeight: "60px",
    footerHeight: "100px",
    rowHeight: "80px",
    bodyHeight: "640px",
    limit: 8,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  dialogUserRef: MdDialogRef<WebBannerForm>;
  dialogUserVideoRef: MdDialogRef<WebBannerVideoForm>;
  config: MdDialogConfig = new MdDialogConfig();

  resize = resizeImage;

  searchKeyword;

  constructor(private mdDialog: MdDialog,
              private appService: AppService,
              private dialogService: DialogService,
              private bannerService: BannerService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));
    this.loadList({page: this.options.page});
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  /*****************************
   *        util functions
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.loadList({page: this.options.page});
        break;
    }
  }

  openWebBannerFormModel(banner?, imageVideo?) {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;
    if(imageVideo == undefined)
      imageVideo = banner.photo.fileType.split('/')[0];
    if (imageVideo === 'video') {
        this.openWebBannerVideoFormModel(banner);
    } else {
        if (banner && banner.imageVideo === 'video') {
            this.openWebBannerVideoFormModel(banner);
        } else {
            this.dialogUserRef = this.mdDialog.open(WebBannerForm, this.config);
            if (banner)
                this.dialogUserRef.componentInstance.webBannerId = banner._id;
            this.dialogUserRef.afterClosed()
                .subscribe(result => {
                    this.loadList({page: this.options.page});
                });
        }
    }
  }

  openWebBannerVideoFormModel(banner) {
    this.dialogUserVideoRef = this.mdDialog.open(WebBannerVideoForm, this.config);
    if (banner)
      this.dialogUserVideoRef.componentInstance.webBannerId = banner._id;

    this.dialogUserVideoRef.afterClosed()
      .subscribe(result => {
        this.loadList({page: this.options.page});
      });
  }

  /*****************************
   *       helper functions
   *****************************/
  bannerpageControl(arr){
    var middle_arr = [];
    for(var i = 0 ; i < arr['banners'].length;i++){
      if(i < this.options.limit){
        middle_arr.push(arr['banners'][i]);
      }
    }
    return middle_arr;
  }

  loadList(event) {
    this.options.page = event.page;

    let skip = (this.options.page - 1) * this.options.limit;
    let params: any = {
      query: {category: 'WEB'},
      limit: this.options.limit,
      skip: skip,
      sort: { displayOrder : 1 },
      populate: ['photo']
    };

    if (this.searchKeyword)
      params.query['url'] = {$regex: ".*" + this.searchKeyword + ".*"};

    this.bannerService.find(params)
      .subscribe(
        (webBannersWrapper) => {
          _.forEach(webBannersWrapper['banners'], (banner, index) => {
            banner['no'] = skip + index + 1;
            if(!banner['photo'] && banner['imageVideo'] === 'video'){
                banner['photo'] = {};
            }
          });
          for(var i =0 ; i < webBannersWrapper['banners'].length;i++){
            var fileType = webBannersWrapper['banners'][i].photo.fileType.split('/');
            webBannersWrapper['banners'][i].fileTypes = fileType[0];
          }
          if(webBannersWrapper['banners'].length > this.options.limit){
            webBannersWrapper['banners'].pop();//[webBannersWrapper['banners'].length -1] = [];
          }
          this.options.rows = webBannersWrapper['banners'];
          this.options.count = webBannersWrapper['total'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  changeDisplayOrder(params, banner) {
    this.bannerService.find(params)
      .subscribe(
        (webBannersWrapper) => {
          if (webBannersWrapper['total'] > 0) {
            let otherBanner = webBannersWrapper['banners'][0];
            
            let currentDisplayOrder = banner.displayOrder;
            let otherDisplayOrder = otherBanner.displayOrder;

            banner.displayOrder = otherDisplayOrder;
            this.bannerService.update(banner)
              .finally(() => {
              })
              .subscribe(
                (data) => {
                  otherBanner.displayOrder = currentDisplayOrder;
                  this.bannerService.update(otherBanner)
                    .finally(() => {
                    })
                    .subscribe(
                      (data) => {
                        this.refreshList();
                      },
                      (err) => {
                      });
                },
                (err) => {
                });
          }
        },
        (err) => {
        }
      );
  }

  refreshList() {
    let skip = (this.options.page - 1) * this.options.limit;
    let params: any = {
      query: { category: 'WEB' },
      limit: this.options.limit,
      skip: skip,
      sort: { displayOrder: 1 },
      populate: ['photo']
    };

    if (this.searchKeyword)
      params.query['url'] = {$regex: ".*" + this.searchKeyword + ".*"};

    this.bannerService.find(params)
      .subscribe(
        (webBannersWrapper) => {
          _.forEach(webBannersWrapper['banners'], (banner, index) => {
            banner['no'] = skip + index + 1;
            if(!banner['photo'] && banner['imageVideo'] === 'video'){
                banner['photo'] = {};
            }
          });
          for(var i =0 ; i < webBannersWrapper['banners'].length;i++){
            var fileType = webBannersWrapper['banners'][i].photo.fileType.split('/');
            webBannersWrapper['banners'][i].fileTypes = fileType[0];
          }
          if(webBannersWrapper['banners'].length > this.options.limit){
            webBannersWrapper['banners'].pop();//[webBannersWrapper['banners'].length -1] = [];
          }
          this.options.rows = webBannersWrapper['banners'];
          this.options.count = webBannersWrapper['total'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  onMoveDown(banner?) {
    let params: any = {
      query: { category: 'WEB', isDeleted: false, displayOrder: { $gt : banner.displayOrder } },
      limit: 1,
      sort: { displayOrder: 1 }
    };

    this.changeDisplayOrder(params, banner);
  }

  onMoveUp(banner?) {
    let params: any = {
      query: { category: 'WEB', isDeleted: false, displayOrder: { $lt : banner.displayOrder } },
      limit: 1,
      sort: { displayOrder: -1 }
    };

    this.changeDisplayOrder(params, banner);
  }

  webBannerRemove(banner) {
    this.dialogService.confirm("알림", "정말 지우시겠습니까?")
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .filter(function (x) {
        return x;
      })
      .flatMap((result) => {
        this.dialogService.loadingSubject.next('spinner');
        return this.bannerService.remove(banner._id);
      })
      .subscribe(
        (result) => {
          this.loadList({page: this.options.page});
        }
      )
  }

}
