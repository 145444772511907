/**
 * Created by Yoon Yong (Andy) Shin on 23/12/2016
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Yoon Yong (Andy) Shin - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Yoon Yong (Andy) Shin <andy.shin@applicat.co.kr>, 23/12/2016
 *
 */


// Browser Lib
declare var _;
import {Observable} from "rxjs";
import "rxjs/add/operator/toPromise";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party Lib

// Project Sources
import {InterceptableHttp} from "../../lib/ng-http-interceptor/http/interceptable-http";
import {config} from "../../app/app.config";


@Injectable()
export class ReleaseRequestService {
  serverUrl = config.serverUrl + '/releaseRequest';

  constructor(private http: InterceptableHttp) {
  }

  count(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/count';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }


  find(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/find';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  findOne(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOne';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  update(params: Object): Observable<any> {
    return this.http
      .put(this.serverUrl, params);
  }

  remove(id): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    params.set("_id", id.toString());

    return this.http
      .delete(this.serverUrl, {search: params});
  }
}
