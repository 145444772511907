/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../../shared/templates";
import {throttleable, resizeImage} from "../../../../shared/services/util";
import {AppService} from "../../../../shared/services/app.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";
import {BannerService} from "../../../../shared/services/banner.service";
import {FileService} from "../../../../shared/services/file.service";

@Component({
    selector: 'store-banner-video-form',
    template: getTemplate('pages/banner/store-banner/store-banner-form/store-banner-video-form')
})
export class StoreBannerVideoForm implements OnInit {

    storeBannerId;
    storeBanner;

    //mangus

    enterFlag;

    formLabel;
    contentSize;


    resize = resizeImage;

    constructor(public dialogRef: MdDialogRef<StoreBannerVideoForm>,
                private bannerService: BannerService,
                private appService: AppService,
                private fileService: FileService,
                private dialogService: DialogService) {
    }

    /*****************************
     *         life cycle
     *****************************/

    ngOnInit() {
        this.recalcSize();
        this.enterFlag = false;
        if (!this.storeBannerId){
            this.formLabel = '배너등록';
        } else {
            this.formLabel = '배너수정';
            this.loadItem();
        }

        this.storeBanner = {
            category: 'STORE',
            url: '',
            photos: [],
            imageVideo: 'video'
        };
    }

    /*****************************
     *        util functions
     *****************************/

    @HostListener('window:resize')
    @throttleable(5)
    recalcSize() {
        this.contentSize = this.dialogService.calcContentSize();
    }

    isValid() {
        let urlRegExp = new RegExp('^(http|https)://');

        if(!this.storeBanner['category'] || this.storeBanner['category'].length <= 0) return false;
        else if(!this.storeBanner['url'] || this.storeBanner['url'].length <= 0 || !urlRegExp.test(this.storeBanner['url'])) return false;
        else return true;
    }

    submit() {
        if(!this.storeBannerId)
            this.create();
        else
            this.update();
    }

    dismiss() {
        this.dialogRef.close();
    }

    /*****************************
     *       helper functions
     *****************************/

    loadItem() {
        this.bannerService
            .findOne({
                query: {_id: this.storeBannerId},
                populate: ['photo']
            })
            .subscribe(
                (bannerWrapper) => {
                    this.storeBanner = bannerWrapper.banner;
                },
                (err) => {
                    let subTitle = '';
                    switch (err.status) {
                        case 400:
                            subTitle = '웹배너를 찾을 수 없습니다.';
                            break;
                        case 500:
                            subTitle = '서버에러';
                            break;
                        default:
                            subTitle = '잘못된 요청입니다.';
                            break;
                    }
                    this.dialogService.message("알림", subTitle);
                }
            );
    }

    create() {
        if (this.isValid()) {
            this.dialogService.loadingSubject.next('spinner');

            let params: any = {
                query: { },
                limit: 1,
                sort: { displayOrder: -1 }
            };
        
            this.bannerService.find(params)
                .subscribe(
                    (storeBannersWrapper) => {
                        let displayOrder = 0;
                        if (storeBannersWrapper['total'] > 0) {
                            displayOrder = storeBannersWrapper['banners'][0].displayOrder;
                        } else {
                            displayOrder = 0;
                        }
        
                        this.storeBanner['displayOrder'] = displayOrder + 1;

                        this.bannerService.create(this.storeBanner)
                            .finally(() => {
                                this.dialogService.loadingSubject.next('hide');
                            })
                            .subscribe(
                                (data) => {
                                    this.dialogRef.close();
                                    this.enterFlag = false;
                                },
                                (err) => {
                                    let subTitle = '';
                                    switch (err.status) {
                                        case 500:
                                            subTitle = '서버에러';
                                            break;
                                        default:
                                            subTitle = '잘못된 요청입니다.';
                                            break;
                                    }
                                    this.dialogService.message("알림", subTitle);
                                });
                    },
                    (err) => {
                    }
                );
        } else {
            this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
        }
    }

    update() {
        if (this.isValid()) {
            this.dialogService.loadingSubject.next('spinner');

            this.bannerService.update(this.storeBanner)
                .finally(() => {
                    this.dialogService.loadingSubject.next('hide');
                })
                .subscribe(
                    (data) => {
                        this.dialogRef.close();
                        this.enterFlag = false;
                    },
                    (err) => {
                        let subTitle = '';
                        switch (err.status) {
                            case 500:
                                subTitle = '서버에러';
                                break;
                            default:
                                subTitle = '잘못된 요청입니다.';
                                break;
                        }
                        this.dialogService.message("알림", subTitle);
                    });
        } else {
            this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
        }
    }
    saveYoutubeLink(youtubeLink, index) {
        if (youtubeLink.indexOf('youtube') !== -1) {
          let re = /https:/gi; 
          let youtubeLink_1 = youtubeLink.replace(re, "http:"); 

          let video_id = youtubeLink.substring(youtubeLink.indexOf('/watch?v=') + 9);
          let pos = video_id.indexOf('&');
          if (pos >= 0) {
            video_id = video_id.substring(0, pos);
          }
          this.enterFlag = true;
          let thumbnail = "https://img.youtube.com/vi/" + video_id +"/default.jpg"
          let youtubeVideoFile = {
            url: youtubeLink_1,
            secure_url: youtubeLink,
            fileType: "video/mpg",
            fileName: "",
            fileSize: "",
            type: 1,
            thumbnail : thumbnail
          };
          this.fileService.createVideo(youtubeVideoFile)
            .finally(() => {          
            })
            .subscribe(
              (data) => {
                this.storeBanner['url'] = data.file.secure_url;
                this.storeBanner['photo'] = data.file._id;
              },
              (err) => { 
              });
        } else {
          this.storeBanner['url'] = '';
          this.storeBanner['photo'] = null;
        }
      }
    
      youtubeLinkChanged(youtubeLink, detailIndex) {
        this.saveYoutubeLink(youtubeLink, detailIndex);
      }
}
