/**
 * Created by Andy Shin Yoon Yong on 14/04/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Andy Shin Yoon Yong - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andy Shin Yoon Yong <developer@applicat.co.kr>, 12/07/2017
 *
 */

// Export lib
declare var moment;

// Angular
import {Pipe, PipeTransform} from "@angular/core";


// moment.defineLocale('kr', {
//   months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
//   monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
//   weekdays: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
//   weekdaysShort: '일_월_화_수_목_금_토'.split('_'),
//   weekdaysMin: '일_월_화_수_목_금_토'.split('_'),
//   longDateFormat: {
//     LT: 'A h시 m분',
//     LTS: 'A h시 m분 s초',
//     L: 'YYYY.MM.DD',
//     LL: 'YYYY년 MMMM D일',
//     LLL: 'YYYY년 MMMM D일 A h시 m분',
//     LLLL: 'YYYY년 MMMM D일 dddd A h시 m분'
//   },
//   calendar: {
//     sameDay: '오늘 LT',
//     nextDay: '내일 LT',
//     nextWeek: 'dddd LT',
//     lastDay: '어제 LT',
//     lastWeek: '지난주 dddd LT',
//     sameElse: 'L'
//   },
//   relativeTime: {
//     future: '%s 후',
//     past: '%s 전',
//     s: '몇 초',
//     ss: '%d초',
//     m: '일분',
//     mm: '%d분',
//     h: '한 시간',
//     hh: '%d시간',
//     d: '하루',
//     dd: '%d일',
//     M: '한 달',
//     MM: '%d달',
//     y: '일 년',
//     yy: '%d년'
//   },
//   ordinalParse: /\d{1,2}일/,
//   ordinal: '%d일',
//   meridiemParse: /오전|오후/,
//   isPM: function (token) {
//     return token === '오후';
//   },
//   meridiem: function (hour, minute, isUpper) {
//     return hour < 12 ? '오전' : '오후';
//   }
// });

@Pipe({name: 'moment'})
export class Moment implements PipeTransform {

  private appMoment = moment;

  constructor() {
  }

  transform(value: any, operation: string, argument?: any): string {

    // this.appMoment.locale(this.config.getConfig('locale'));

    let date = new Date(value);

    if (!date)
      return '';

    // TODO: get array of arguments and ...arguments instead
    let formattedDate = this.appMoment(date)[operation](argument);
    return formattedDate;
  }
}
