/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Browser Lib
declare var _;
import "rxjs/add/operator/toPromise";
import {Observable} from "rxjs";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party Lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class FileService {

  private serverUrl = config.serverUrl + "/file";

  constructor(private http: InterceptableHttp) {
  }

  createVideo(params: Object): Observable<any> {
    return this.http.post(this.serverUrl + "/video", params);
  }

  update(params: Object): Observable<any> {
    return this.http
      .put(this.serverUrl, params);
  }

  remove(_id: string): Observable<any> {
    let param: URLSearchParams = new URLSearchParams();

    param.set("_id", _id.toString());

    return this.http
      .delete(this.serverUrl, {search: param});
  }

  findOne(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOne';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key) => {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

}
