/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {SerialService} from "../../../shared/services/serial.service";

@Component({
  selector: 'genuine-form',
  template: getTemplate('pages/genuine/genuine-form/genuine-form')
})
export class GenuineForm implements OnInit {

  serialId;
  serial;

  contentSize;
  formLabel;

  constructor(public dialogRef: MdDialogRef<GenuineForm>,
              private dialogService: DialogService,
              private serialService: SerialService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    this.serial = {
      serialNumber: '',
      productName: '',
      registered: {
        identifier: '',
        nickname: ''
      }
    };

    this.loadItem();
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.serialService.findOne({
      query: {_id: this.serialId},
      populate: ['registered']
    })
      .subscribe(
        (serialWrapper) => {
          this.serial = serialWrapper['serial'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  cancelRegistered(){
    this.serialService.cancelRegistered({
      serialNumber: this.serial['serialNumber'],
      productName: this.serial['productName'],
      registered: this.serial['registered']
    })
      .subscribe(
        (serialWrapper) => {
          this.dismiss();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '잘못된 요청입니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

}
