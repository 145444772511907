/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {FaqService} from "../../../shared/services/faq.service";

@Component({
  selector: 'faq-form',
  template: getTemplate('pages/faq/faq-form/faq-form')
})
export class FaqForm implements OnInit {

  faqId;
  faq;

  contentSize;
  formLabel;

  constructor(public dialogRef: MdDialogRef<FaqForm>,
              private dialogService: DialogService,
              private faqService: FaqService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    this.faq = {
      questionKr: '',
      questionEn: '',
      answerKr: '',
      answerEn: ''
    };

    if (!this.faqId){
      this.formLabel = 'FAQ 등록';
    } else {
      this.formLabel = 'FAQ 수정';
      this.loadItem();
    }

  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    if(!this.faq['questionKr'] || this.faq['questionKr'].length <= 0) return false;
    else if(!this.faq['questionEn'] || this.faq['questionEn'].length <= 0) return false;
    else if(!this.faq['answerKr'] || this.faq['answerKr'].length <= 0) return false;
    else if(!this.faq['answerEn'] || this.faq['answerEn'].length <= 0) return false;
    else return true;
  }

  submit() {
    if(!this.faqId)
      this.create();
    else
      this.update();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.faqService.findOne({
      query: {_id: this.faqId}
    })
      .subscribe(
        (faqWrapper) => {
          this.faq = faqWrapper['faq'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  create() {
    this.dialogService.loadingSubject.next('spinner');

    this.faqService.create(this.faq)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (data) => {
          this.dialogRef.close();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        });
  }

  update() {
    this.dialogService.loadingSubject.next('spinner');

    this.faqService.update(this.faq)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (data) => {
          this.dialogRef.close();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        });
  }

}
