/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Angular
import {Component, OnInit} from '@angular/core';

// Project Sources
import {getTemplate} from "../../shared/templates";
import {AppService} from "../../shared/services/app.service";
import {VersionService} from "../../shared/services/version.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'version',
  template: getTemplate('pages/version/version')
})
export class Version implements OnInit {

  appEventDisposor;

  versions;

  constructor(private appService: AppService,
              private versionService: VersionService,
              private dialogService: DialogService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.versions = [];
    this.loadVersions();
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  /*****************************
   *        util functions
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.loadVersions();
        break;
    }
  }

  isValid(version){
    if(!version['platform'] || version['platform'] == '') return false;
    else if(!version['packageName'] || version['packageName'] == '') return false;
    else if(!version['version'] || version['version'] == '') return false;
    else return true;
  }

  /*****************************
   *       helper functions
   *****************************/

  loadVersions(){
    this.versionService.find({sort: {_id: 1}})
      .subscribe(
        (versionsWrapper) => {
          this.versions = versionsWrapper['versions'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

  update(version){
    if(this.isValid(version)){
      this.dialogService.loadingSubject.next('spinner');

      this.versionService.update(version)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (versionWrapper) => {
            version = versionWrapper['version'];
            this.dialogService.message("알림", '변경되었습니다.');
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        )
    } else {
      this.dialogService.message("알림", "입력된 정보를 확인해주세요.");
    }

  }

}
