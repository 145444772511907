/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Browser Lib
import 'core-js/client/shim';
import 'es6-promise';
import 'zone.js/dist/zone';

// Angular
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

// Project Sources
import {AppModule} from './app.module';

import {enableProdMode} from "@angular/core";

enableProdMode();

platformBrowserDynamic().bootstrapModule(AppModule);
