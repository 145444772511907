/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Angular
import {ModuleWithProviders} from "@angular/core";
import {RouterModule} from "@angular/router";

// Project Sources
import {PopUp} from "../pages/popup/popup";
import {WebBanner} from "../pages/banner/web-banner/web-banner";
import {StoreBanner} from "../pages/banner/store-banner/store-banner";
import {Faq} from "../pages/faq/faq";
import {Product} from "../pages/product/product";
import {Serial} from "../pages/serial/serial";
import {Genuine} from "../pages/genuine/genuine";
import {Invoice} from "../pages/invoice/invoice";
import {User} from "../pages/user/user";
import {Push} from "../pages/push/push";
import {Mail} from "../pages/mail/mail";
import {Version} from "../pages/version/version";

export const routes = [
  {
    path: '',
    redirectTo: '/web-banner',
    pathMatch: 'full',
  },
  {
    label: '팝업 관리',
    path: 'popup',
    component: PopUp
  },
  {
    label: '웹 배너 관리',
    path: 'web-banner',
    component: WebBanner
  },
  {
    label: '스토어 배너 관리',
    path: 'store-banner',
    component: StoreBanner
  },
  {
    label: 'FAQ 관리',
    path: 'faq',
    component: Faq
  },
  {
    label: '상품 관리',
    path: 'product',
    component: Product
  },
  {
    label: '시리얼 관리',
    path: 'serial',
    component: Serial
  },
  {
    label: '정품 인증 관리',
    path: 'genuine',
    component: Genuine
  },
  {
    label: '주문 내역 관리',
    path: 'invoice',
    component: Invoice
  },
  {
    label: '회원 관리',
    path: 'user',
    component: User
  },
  {
    label: '푸시알림',
    path: 'push',
    component: Push
  },
  {
    label: '고객 메일 전송',
    path: 'mail',
    component: Mail
  },
  {
    label: '버전 관리',
    path: 'version',
    component: Version
  }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);