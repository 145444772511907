/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import { Component, OnInit, HostListener, Input, Pipe, PipeTransform } from '@angular/core';
import { MdDialogRef, ConnectedPositionStrategy } from "@angular/material";

// Angular Third Party Lib
import {FileUploader, Headers} from "../../../lib/ng2-file-upload/components/file-upload/file-uploader.class";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {resizeImage, throttleable} from "../../../shared/services/util";
import {config} from "../../../app/app.config";
import {AppService} from "../../../shared/services/app.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {FileService} from "../../../shared/services/file.service";
import {ProductService} from "../../../shared/services/product.service";
import { Jsonp } from '@angular/http';

@Component({
  selector: 'product-form',
  template: getTemplate('pages/product/product-form/product-form')
})
export class ProductForm implements OnInit {

  productId;
  product;

  productPhoto;

  contentSize;
  formLabel;
  uploader: FileUploader;

  maxDetailFilesCount = 5;
  maxOptionsCount = 10;
  maxAttributesCount = 10;

  maxFiles = (amount) => {
    return {
      name: 'maxFiles',
      fn: function (file) {
        return this.queue.length < amount
      }
    }
  };

  imageOnly = {
    name: 'imageOnly',
    fn: function (file) {
      return file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/gif";
    }
  };

  maxFileSize = (size) => {
    return {
      name: 'maxFileSize',
      fn: function (file) {
        return 1024*1024;
      }
    }
  }

  resize = resizeImage;

  constructor(public dialogRef: MdDialogRef<ProductForm>,
              private appService: AppService,
              private fileService: FileService,
              private dialogService: DialogService,
              private productService: ProductService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    this.product = {
      name: '',
      summary: '',
      soldOut: false,
      price: null,
      salePrice: null,
      photos: [],
      detailFiles: [],
      option: [],
      attribute: []
    };
    this.productPhoto = null;

    if (!this.productId) {
      this.formLabel = '상품 등록';
      while (this.product['detailFiles'].length < this.maxDetailFilesCount) {
        this.product['detailFiles'].push({
          type: 0, url: ""
        });
      }
    } else {
      this.formLabel = '상품 수정';
      this.loadItem();
    }
    
    let headers = Array<Headers>();
    if (this.appService.user && this.appService.token) {
      headers.push({
        name: 'Authorization',
        value: 'Bearer ' + this.appService.token,
      });
    }
    this.uploader = new FileUploader(
      _.assign({
          filters: [
            {
              name: '.DS_Store',
              fn: (file) => {
                let filename = file.name.toLowerCase();
                return filename.indexOf('.ds_store') < 0;
              }
            }
          ]
        },
        {
          url: config.serverUrl + "/file",
          filters: [this.maxFiles(1), this.imageOnly],
          headers: headers
        })
    );

    this.uploader.onBuildItemForm = (item, form) => {
      if (item.some['tag'] == 'THUMBNAIL')
        form.append('tag', 'THUMBNAIL');
      else if (item.some['tag'] == 'DETAIL_FILE_0')
        form.append('tag', 'DETAIL_FILE_0');
      else if (item.some['tag'] == 'DETAIL_FILE_1')
        form.append('tag', 'DETAIL_FILE_1');
      else if (item.some['tag'] == 'DETAIL_FILE_2')
        form.append('tag', 'DETAIL_FILE_2');
      else if (item.some['tag'] == 'DETAIL_FILE_3')
        form.append('tag', 'DETAIL_FILE_3');
      else if (item.some['tag'] == 'DETAIL_FILE_4')
        form.append('tag', 'DETAIL_FILE_4');
    };

    this.uploader.completeAll.subscribe(() => {
      if (this.uploader.queue[0].result.file.tag == 'THUMBNAIL') {
        this.productPhoto = this.uploader.queue[0].result.file;
      } else if (this.uploader.queue[0].result.file.tag == 'DETAIL_FILE_0') {
        this.product['detailFiles'][0] = this.uploader.queue[0].result.file;
      } else if (this.uploader.queue[0].result.file.tag == 'DETAIL_FILE_1') {
        this.product['detailFiles'][1] = this.uploader.queue[0].result.file;
      } else if (this.uploader.queue[0].result.file.tag == 'DETAIL_FILE_2') {
        this.product['detailFiles'][2] = this.uploader.queue[0].result.file;
      } else if (this.uploader.queue[0].result.file.tag == 'DETAIL_FILE_3') {
        this.product['detailFiles'][3] = this.uploader.queue[0].result.file;
      } else if (this.uploader.queue[0].result.file.tag == 'DETAIL_FILE_4') {
        this.product['detailFiles'][4] = this.uploader.queue[0].result.file;
      }
    });

  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    if (!this.product['name'] || this.product['name'].length <= 0) return false;
    else if (!this.product['summary'] || this.product['summary'].length <= 0) return false;
    else if (this.product['price'] < 0) return false;
    else if (!this.checkOptionValid()) return false;
    else if (!this.checkAttributeValid()) return false;
    else if (!this.productPhoto) return false;
    else if (!this.product['detailFiles'][0]) return false;
    else if (!this.product['detailFiles'][1]) return false;
    else if (!this.product['detailFiles'][2]) return false;
    else if (!this.product['detailFiles'][3]) return false;
    else if (!this.product['detailFiles'][4]) return false;
    else if (!this.product['isDog'] && !this.product['isCat']) return false;
    else return true;
  }

  checkOptionValid() {
    let result = true;

    if (this.product['option'].length > 0) {
      if (!this.product['option'][0]['name']) {
        result = false;
      } else if(this.product.option[0]['properties'].length > 0) {
        _.pickBy(this.product.option[0]['properties'], (propertyValue) => {
          if (!propertyValue['name'] || propertyValue['name'] == '') {
            result = false;
          }
        });
      } else {
        result = false;
      }
    }

    return result;
  }

  checkAttributeValid() {
    let result = true;

    if (this.product['attribute'].length > 0) {
      for (let i = 0; i < this.product['attribute'].length; i++) {
        if (!this.product['attribute'][i].name || this.product['attribute'][i].name == '') {
          result = false;
          break;
        }
      }
    }

    return result;
  }

  upload(files, tag, index) {
    this.uploader.clearQueue();
    if (files && files.length > 0) {
      _.forEach(files, (file) => {
        if (!file['tag']) {
          if (index > -1)
            file['tag'] = tag + index.toString();
          else
            file['tag'] = tag;
        }
      });

      this.uploader.addToQueue(files);
    }

    this.uploader.uploadAll();
  }

  optionAdd(index) {
    this.product.soldOut = false;

    if (this.product['option'].length < this.maxOptionsCount) {
      this.product['option'].push({
        name: '',
        properties:[
          {
            name: '',
            price:0,
            soldOut: false
          }
        ]
      });
    } else {
      this.dialogService.message("알림", "옵션은 최대 " + this.maxOptionsCount + "개까지 등록가능합니다.");
    }
  }

  optionClear() {
      this.product['option'] = [];
  }

  optionRemove(optionIndex) {
    this.product['option'].splice(optionIndex, 1);
  }

  propertyAdd(index) {
    for (var i = 0 ; i < this.product['option'].length;i++) {
      if (index == i) {
        if (this.product['option'][i]['properties']) {
          this.product['option'][i]['properties'].push({
            name: '',
            price:0,
            soldOut: false
          });
        }
      }
    }
  }

  propertyRemove(propertyIndex, optionIndex) {
    this.product['option'][optionIndex]['properties'].splice(propertyIndex, 1);
  }

  propertyClear(optionIndex) {
    this.product['option'][optionIndex]['properties'] = [];
    this.product['soldOut'] = false;
  }

  changePropertySoldOut() {
    let findNotSoldOut = _.find(this.product['option']['properties'], {soldOut: false});
    if (findNotSoldOut)
      this.product['soldOut'] = false;
    else
      this.product['soldOut'] = true;
  }

  changeCategory(category) {
  }

  attributeAdd(index) {
    this.product.soldOut = false;

    if (this.product['attribute'].length < this.maxAttributesCount) {
      this.product['attribute'].push({
        name: '',
        price: 0,
        soldOut: false
      });
    } else {
      this.dialogService.message("알림", "속성은 최대 " + this.maxAttributesCount + "개까지 등록가능합니다.");
    }
  }

  attributeClear() {
      this.product['attribute'] = [];
  }

  attributeRemove(attributeIndex) {
    this.product['attribute'].splice(attributeIndex, 1);
  }

  changeAttributeSoldOut(attributeIndex) {
    let findNotSoldOut = _.find(this.product['attribute'], {soldOut: false});
    if (findNotSoldOut)
      this.product['attribute'][attributeIndex]['soldOut'] = false;
    else
      this.product['attribute'][attributeIndex]['soldOut'] = true;
  }

  submit() {
    if (!this.productId)
      this.create();
    else
      this.update();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.productService.findOne({
      query: {_id: this.productId},
      populate: ['photos', 'detailFiles']
    })
      .subscribe(
        (productWrapper) => {
          this.product = productWrapper['product'];
          this.productPhoto = _.find(productWrapper['product'].photos, {'tag': 'THUMBNAIL'});          
          this.product['photos'] = [];

          this.product['detailFiles'] = productWrapper['product'].detailFiles;

          this.product['isDog'] = false; this.product['isCat'] = false;
          if (this.product['category'] === 1) {
            this.product['isDog'] = true;
          } else if (this.product['category'] === 2) {
            this.product['isCat'] = true;
          } else if (this.product['category'] === 3) {
            this.product['isDog'] = true;
            this.product['isCat'] = true;
          }
          
          while (this.product['detailFiles'].length < this.maxDetailFilesCount) {
            this.product['detailFiles'].push({
              type: 0, url: ""
            });
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  create() {
    if (this.isValid()) {
      this.dialogService.loadingSubject.next('spinner');

      this.product['category'] = 0;
      if (this.product['isDog'] === true && this.product['isCat'] === true) {
        this.product['category'] = 3;
      } else if (this.product['isDog'] === true) {
        this.product['category'] = 1;
      } else if (this.product['isCat'] === true) {
        this.product['category'] = 2;
      }

      if (this.product.photos.length <= 0)
        this.product.photos.push(this.productPhoto._id);
      
      let newDetailFiles = [];
      for (let i = 0; i < this.product['detailFiles'].length; i++) {
        let detailFile = this.product['detailFiles'][i];
        if (detailFile.type == 1) {
          if (detailFile._id) {
            if (detailFile.url.indexOf('youtube') === -1) {
              this.dialogService.message("알림", "Youtube Link를 입력하고 Enter건을 정확히 눌러주세요!"); 
              this.dialogService.loadingSubject.next('hide');
              return;
            } else {
              newDetailFiles.push(detailFile._id);
            }
          } else {
            this.dialogService.message("알림", "Youtube Link를 입력하고 Enter건을 정확히 눌러주세요!"); 
            this.dialogService.loadingSubject.next('hide');
            return;
          }
        } else if (detailFile.type == 2) {
          if (detailFile._id) {
            if (detailFile.url.indexOf('youtube') !== -1) {
              this.dialogService.message("알림", "이미지를 정확히 업로드해주세요!"); 
              this.dialogService.loadingSubject.next('hide');
              return;
            } else {
              newDetailFiles.push(detailFile._id);
            }
          } else {
            this.dialogService.message("알림", "이미지를 정확히 업로드해주세요!"); 
            this.dialogService.loadingSubject.next('hide');
            return;
          }
        }
      }

      this.product['detailFiles'] = newDetailFiles;

      let params: any = {
        query: { },
        limit: 1,
        sort: { displayOrder: -1 }
      };

      this.productService.find(params)
        .subscribe(
          (productsWrapper) => {
            let displayOrder = 0;
            if (productsWrapper['total'] > 0) {
              displayOrder = productsWrapper['products'][0].displayOrder;
            } else {
              displayOrder = 0;
            }

            this.product['displayOrder'] = displayOrder + 1;

            this.productService.create(this.product)
              .finally(() => {
                this.dialogService.loadingSubject.next('hide');
              })
              .subscribe(
                (data) => {
                  this.dialogRef.close();
                },
                (err) => {
                  let subTitle = '';
                  switch (err.status) {
                    case 500:
                      subTitle = '서버에러';
                      break;
                    default:
                      subTitle = '잘못된 요청입니다.';
                      break;
                  }
                  this.dialogService.message("알림", subTitle);
                });
          },
          (err) => {
          }
        );
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  update() {
    if (this.isValid()) {
      this.dialogService.loadingSubject.next('spinner');

      this.product['category'] = 0;
      if (this.product['isDog'] === true && this.product['isCat'] === true) {
        this.product['category'] = 3;
      } else if (this.product['isDog'] === true) {
        this.product['category'] = 1;
      } else if (this.product['isCat'] === true) {
        this.product['category'] = 2;
      }

      this.product.photos.push(this.productPhoto._id);

      let newDetailFiles = [];
      for (let i = 0; i < this.product['detailFiles'].length; i++) {
        let detailFile = this.product['detailFiles'][i];
        if (detailFile.type == 1) {
          if (detailFile._id) {
            if (detailFile.url.indexOf('youtube') === -1) {
              this.dialogService.message("알림", "Youtube Link를 입력하고 Enter건을 정확히 눌러주세요!"); 
              this.dialogService.loadingSubject.next('hide');
              return;
            } else {
              newDetailFiles.push(detailFile._id);
            }
          } else {
            this.dialogService.message("알림", "Youtube Link를 입력하고 Enter건을 정확히 눌러주세요!"); 
            this.dialogService.loadingSubject.next('hide');
            return;
          }
        } else if (detailFile.type == 2) {
          if (detailFile._id) {
            if (detailFile.url.indexOf('youtube') !== -1) {
              this.dialogService.message("알림", "이미지를 정확히 업로드해주세요!"); 
              this.dialogService.loadingSubject.next('hide');
              return;
            } else {
              newDetailFiles.push(detailFile._id);
            }
          } else {
            this.dialogService.message("알림", "이미지를 정확히 업로드해주세요!"); 
            this.dialogService.loadingSubject.next('hide');
            return;
          }
        }
      }

      this.product['detailFiles'] = newDetailFiles;
      
      this.productService.update(this.product)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  photoRemove(photo, index) { 
    this.dialogService.loadingSubject.next('spinner');

    this.fileService.remove(photo._id)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        () => {
          this.uploader.clearQueue();
          if (index < 0) {
            this.productPhoto = null;
          } else {
            this.product['detailFiles'][index] = { type: 2, url: "" };
          }
          /*if (photo.tag == 'THUMBNAIL')
            this.productPhoto = null;
          else if (photo.tag == 'DETAIL_FILE_0')
            this.product['detailFiles'][0] = { type: 2, url: "" };
          else if (photo.tag == 'DETAIL_FILE_1') 
            this.product['detailFiles'][1] = { type: 2, url: "" };
          else if (photo.tag == 'DETAIL_FILE_2') 
            this.product['detailFiles'][2] = { type: 2, url: "" };
          else if (photo.tag == 'DETAIL_FILE_3') 
            this.product['detailFiles'][3] = { type: 2, url: "" };
          else if (photo.tag == 'DETAIL_FILE_4') 
            this.product['detailFiles'][4] = { type: 2, url: "" };*/
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  saveYoutubeLink(youtubeLink, index) {
    if (youtubeLink.indexOf('youtube') !== -1) {
      let re = /https:/gi; 
      let youtubeLink_1 = youtubeLink.replace(re, "http:"); 
      
      // made by mangus
      let video_id = youtubeLink.substring(youtubeLink.indexOf('/watch?v=') + 9);
      let pos = video_id.indexOf('&');
      if (pos >= 0) {
        video_id = video_id.substring(0, pos);
      }

      let thumbnail = "https://img.youtube.com/vi/" + video_id +"/default.jpg"
      let youtubeVideoFile = {
        url: youtubeLink_1,
        secure_url: youtubeLink,
        fileType: "video/mpg",
        fileName: "",
        fileSize: "",
        type: 1,
        thumbnail : thumbnail
      };
      this.fileService.createVideo(youtubeVideoFile)
        .finally(() => {          
        })
        .subscribe(
          (data) => {
            this.product['detailFiles'][index] = data.file;
          },
          (err) => { 
          });
    } else {
      this.product['detailFiles'][index] = { type: 1, url: "" };
    }
  }

  youtubeLinkChanged(youtubeLink, detailIndex) {
    this.saveYoutubeLink(youtubeLink, detailIndex);
  }

  clicked() {
  }

}