/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit} from '@angular/core';

// Angular Third Party Lib
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../shared/templates";
import {AppService} from "../../shared/services/app.service";
import {UserService} from "../../shared/services/user.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";
import {UserForm} from "./user-form/user-form";

@Component({
  selector: 'user',
  template: getTemplate('pages/user/user')
})
export class User implements OnInit {

  appEventDisposor;

  options = {
    rows: [],
    selected: null,
    headerHeight: "60px",
    footerHeight: "100px",
    rowHeight: "80px",
    bodyHeight: "640px",
    limit: 8,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  dialogUserRef: MdDialogRef<UserForm>;
  config: MdDialogConfig = new MdDialogConfig();

  searchKeyword;

  constructor(private mdDialog: MdDialog,
              private appService: AppService,
              private dialogService: DialogService,
              private userService: UserService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.loadList({page: this.options.page});
  }

  ngOnDestroy() {
    if (this.appEventDisposor)
      this.appEventDisposor.unsubscribe();
  }

  /*****************************
   *        util functions
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.loadList({page: this.options.page});
        break;
    }
  }

  openUserFormModel(user?) {
    this.dialogService.resizeModal(this.config);
    this.config.disableClose = true;

    this.dialogUserRef = this.mdDialog.open(UserForm, this.config);
    if(user)
      this.dialogUserRef.componentInstance.userId = user._id;

    this.dialogUserRef.afterClosed()
      .subscribe(result => {
        this.loadList({page: this.options.page});
      });
  }

  /*****************************
   *       helper functions
   *****************************/

  loadList(event) {
    this.options.page = event.page;

    let skip = (this.options.page - 1) * this.options.limit;
    let params: any = {
      query: {},
      limit: this.options.limit,
      skip: skip,
      sort: {_id: -1}
    };

    if (this.searchKeyword){
      params.query['$or'] = [];
      params.query['$or'].push({identifier: {$regex: ".*" + this.searchKeyword + ".*"}});
      params.query['$or'].push({nickname: {$regex: ".*" + this.searchKeyword + ".*"}});
      if(!isNaN(this.searchKeyword)){
        params.query['$or'].push({'deliveryInfo.phone': this.searchKeyword});
      }
    }

    this.userService.getUserList(params)
      .subscribe(
        (usersWrapper) => {
          _.forEach(usersWrapper['users'], (user, index) => {
            user['no'] = skip + index + 1;
          });

          this.options.rows = usersWrapper['users'];
          this.options.count = usersWrapper['total'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  toggleActivateUserOnlyAdmin(user){
    this.dialogService.loadingSubject.next('spinner');

    this.userService.toggleActivateUserOnlyAdmin(user['identifier'])
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (userWrapper) => {
          if(user['identifier'] == this.appService.user['identifier']){
            this.dialogService.confirm('알림', '관리자 계정이 비활성화 되었습니다. 로그아웃됩니다.')
              .subscribe((result) => {
                this.appService.user = null;
                this.appService.token = null;
                user['isDeleted'] = userWrapper['user'].isDeleted;
                this.appService.sendEvent('logout');
              })
          } else {
            user['isDeleted'] = userWrapper['user'].isDeleted;
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      )
  }

}
