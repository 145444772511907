/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Browser Lib
declare var _;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Project Sources
import {config} from "../../../../app/app.config";
import {getTemplate} from "../../../../shared/templates";
import {throttleable, resizeImage} from "../../../../shared/services/util";
import {AppService} from "../../../../shared/services/app.service";
import {DialogService} from "../../../../shared/components/dialog-message/dialog-message.service";
import {BannerService} from "../../../../shared/services/banner.service";
import {FileService} from "../../../../shared/services/file.service";

@Component({
  selector: 'web-banner-video-form',
  template: getTemplate('pages/banner/web-banner/web-banner-form/web-banner-video-form')
})
export class WebBannerVideoForm implements OnInit {

  webBannerId;
  webBanner;

  formLabel;

  contentSize;

  resize = resizeImage;

  constructor(public dialogRef: MdDialogRef<WebBannerVideoForm>,
              private bannerService: BannerService,
              private appService: AppService,
              private fileService: FileService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    if (!this.webBannerId) {
      this.formLabel = '배너등록';
    } else {
      this.formLabel = '배너수정';
      this.loadItem();
    }

    this.webBanner = {
      category: 'WEB',
      url: '',
      photo: null,
      imageVideo: 'video'
    };
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    let urlRegExp = new RegExp('^(http|https)://');
    if (!this.webBanner['category'] || this.webBanner['category'].length <= 0) return false;
    else if (!this.webBanner['url'] || this.webBanner['url'].length <= 0 || !urlRegExp.test(this.webBanner['url'])) return false;
    else return true;
  }

  submit() {
    if(!this.webBannerId)
      this.create();
    else
      this.update();
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.bannerService.findOne({
      query: {_id: this.webBannerId},
      populate: ['photo']
    })
      .subscribe(
        (bannerWrapper) => {
          this.webBanner = bannerWrapper.banner;
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '웹배너를 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  create() {
    if (this.isValid()) {
      this.dialogService.loadingSubject.next('spinner');
      
      let params: any = {
        query: { },
        limit: 1,
        sort: { displayOrder: -1 }
      };

      this.bannerService.find(params)
        .subscribe(
          (bannerWrapper) => {
            let displayOrder = 0;
            if (bannerWrapper['total'] > 0) {
              displayOrder = bannerWrapper['banners'][0].displayOrder;
            } else {
              displayOrder = 0;
            }

            this.webBanner['displayOrder'] = displayOrder + 1;

            this.bannerService.create(this.webBanner)
              .finally(() => {
                this.dialogService.loadingSubject.next('hide');
              })
              .subscribe(
                (data) => {
                  this.dialogRef.close();
                },
                (err) => {
                  let subTitle = '';
                  switch (err.status) {
                    case 500:
                      subTitle = '서버에러';
                      break;
                    default:
                      subTitle = '잘못된 요청입니다.';
                      break;
                  }
                  this.dialogService.message("알림", subTitle);
                });
          },
          (err) => {
          }
        );
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }

  }

  update() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.bannerService.update(this.webBanner)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  saveYoutubeLink(youtubeLink, index) {
    if (youtubeLink.indexOf('youtube') !== -1) {
      let re = /https:/gi; 
      let youtubeLink_1 = youtubeLink.replace(re, "http:"); 
      
      // made by mangus
      let video_id = youtubeLink.substring(youtubeLink.indexOf('/watch?v=') + 9);
      let pos = video_id.indexOf('&');
      if (pos >= 0) {
        video_id = video_id.substring(0, pos);
      }

      let thumbnail = "https://img.youtube.com/vi/" + video_id +"/default.jpg"
      let youtubeVideoFile = {
        url: youtubeLink_1,
        secure_url: youtubeLink,
        fileType: "video/mpg",
        fileName: "",
        fileSize: "",
        type: 1,
        thumbnail : thumbnail
      };
      this.fileService.createVideo(youtubeVideoFile)
        .finally(() => {          
        })
        .subscribe(
          (data) => {
            this.webBanner['url'] = data.file.secure_url;
            this.webBanner['photo'] = data.file._id;
          },
          (err) => { 
          });
    } else {
      this.webBanner['url'] = '';
      this.webBanner['photo'] = null;
    }
  }

  youtubeLinkChanged(youtubeLink, detailIndex) {
    this.saveYoutubeLink(youtubeLink, detailIndex);
  }

}
