/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, HostListener, OnInit} from "@angular/core";
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {TransactionService} from "../../../shared/services/transaction.service";

@Component({
  selector: 'change-status-form',
  template: getTemplate('pages/invoice/change-status-form/change-status-form')
})
export class ChangeStatusForm implements OnInit{

  invoiceId;
  invoice;

  statusList;
  selectedStatus;

  contentSize;

  constructor(public dialogRef: MdDialogRef<ChangeStatusForm>,
              private dialogService: DialogService,
              private transactionService: TransactionService){}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit(){
    this.statusList = [
      {name: '주문취소', value: 'ORDER_CANCELLED'},
      {name: '배송준비', value: 'DELIVERY_PREPARE'},
      {name: '배송중', value: 'DELIVERING'},
      {name: '배송완료', value: 'DELIVERED'},
      {name: '교환요청', value: 'EXCHANGE_REQUESTED'},
      {name: '교환완료', value: 'EXCHANGE_DONE'},
      {name: '환불요청', value: 'REFUND_REQUESTED'},
      {name: '환불완료', value: 'REFUND_DONE'},
      {name: '주문완료', value: 'ORDER_DONE'}
    ];
    this.recalcSize();
    this.loadItem();
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  checkAvailableChangeStatus(){
    switch(this.invoice.status){
      case 'PAYMENT_FAILED':
        return false;
      case 'ORDER_CANCELLED':
        return false;
      case 'REFUND_DONE':
        return false;
    }
    if(this.invoice.paymentType !='bank' && this.invoice.status == 'PAYMENT_PENDING')
      return false;

    return true;
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.transactionService.findOneInvoice({
      query: {_id: this.invoiceId},
      populate: ['owner']
    })
      .subscribe(
        (invoiceWrapper) => {
          this.invoice = invoiceWrapper['invoice'];

          if(!this.checkAvailableChangeStatus()){
            this.dialogService.message("알림", '실패 또는 취소된 주문은 상태를 변경할 수 없습니다.');
            this.dismiss();
          }

          if(this.invoice.paymentType !='bank')
            this.statusList.splice(_.findIndex(this.statusList, {value: 'PAYMENT_DONE'}), 1);
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  submit(){
    this.dialogService.loadingSubject.next('spinner');

    let paymentId = this.invoice.payments[0]._id;

    this.transactionService.changeStatus(paymentId, this.selectedStatus.value)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        (invoiceWrapper) => {
          this.dialogService.message("알림", '변경이 완료되었습니다.');
          this.dismiss();
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '주문 내역을 찾을 수 없습니다.';
              break;
            case 422:
              subTitle = '고객센터 또는 PG사로 문의가 필요합니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

}