/**
 * Created by PHILIP on 10/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 10/07/2017
 *
 */

// Angular
import {Component, OnInit} from '@angular/core';

// Project Sources
import {getTemplate} from "../../shared/templates";
import {MailService} from "../../shared/services/mail.service";
import {config} from "../../app/app.config";
import {AppService} from "../../shared/services/app.service";
import {FileService} from "../../shared/services/file.service";
import {DialogService} from "../../shared/components/dialog-message/dialog-message.service";

import {FileUploader, Headers} from "../../lib/ng2-file-upload/components/file-upload/file-uploader.class";

@Component({
  selector: 'mail',
  template: getTemplate('pages/mail/mail')
})
export class Mail implements OnInit {

  mail;

  footerImage;

  uploader: FileUploader;

  maxFiles = (amount) => {
    return {
      name: 'maxFiles',
      fn: function (file) {
        return this.queue.length < amount
      }
    }
  };

  imageOnly = {
    name: 'imageOnly',
    fn: function (file) {
      return file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg";
    }
  };

  constructor(private mailService: MailService,
              private dialogService: DialogService,
              private fileService: FileService,
              private appService: AppService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.mail = {
      title: '',
      message: '안녕하세요.'
    };

    this.footerImage = null;

    let headers = Array<Headers>();
    if (this.appService.user && this.appService.token) {
      headers.push({
        name: 'Authorization',
        value: 'Bearer ' + this.appService.token,
      });
    }
    this.uploader = new FileUploader(
      _.assign({
          filters: [
            {
              name: '.DS_Store',
              fn: (file) => {
                let filename = file.name.toLowerCase();
                return filename.indexOf('.ds_store') < 0;
              }
            }
          ]
        },
        {
          url: config.serverUrl + "/file",
          filters: [this.maxFiles(1), this.imageOnly],
          headers: headers
        })
    );

    this.uploader.onBuildItemForm = (item, form) => {
      if (item.some['tag'] == 'FOOTER')
        form.append('tag', 'FOOTER');
    };

    this.uploader.completeAll.subscribe(() => {
      if (this.uploader.queue[0].result.file.tag == 'FOOTER') {
        this.footerImage = this.uploader.queue[0].result.file;
        this.mail['footer_image'] = this.footerImage.secure_url;
      }
    });

    this.fileService.findOne({
      query: { tag: "FOOTER", isDeleted: false }
    })
      .subscribe(
        (fileWrapper) => {
          this.footerImage = fileWrapper['file'];
          if (fileWrapper['file'] === null) return;

          this.mail['footer_image'] = this.footerImage.secure_url;
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  /*****************************
   *        util functions
   *****************************/

  isValid() {
    if (!this.mail['title'] || this.mail['title'].length <= 0) return false;
    else if (!this.mail['message'] || this.mail['message'].length <= 0) return false;
    // else if (!this.footerImage) return false;
    else return true;
  }

  /*****************************
   *       helper functions
   *****************************/

  sendToClient() {
    if (this.isValid()) {
      this.dialogService.loadingSubject.next('spinner');

      this.mailService.sendToClients(this.mail)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          () => {
            this.dialogService.message("알림", '메일이 전송되었습니다.');
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        )
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  upload(files, tag) {
    this.fileService.findOne({
      query: { tag: "FOOTER", isDeleted: false }
    })
      .subscribe(
        (fileWrapper) => {
          this.footerImage = fileWrapper['file'];
          if (fileWrapper['file'] === null) {
            this.uploader.clearQueue();
            if (files && files.length > 0) {
              _.forEach(files, (file) => {
                if (!file['tag'])
                  file['tag'] = tag;
              });

              this.uploader.addToQueue(files);
            }

            this.uploader.uploadAll();
          } else {
            this.fileService.remove(this.footerImage._id)
              .finally(() => {
                this.dialogService.loadingSubject.next('hide');
              })
              .subscribe(
                () => {
                  this.uploader.clearQueue();
                  if (files && files.length > 0) {
                    _.forEach(files, (file) => {
                      if (!file['tag'])
                        file['tag'] = tag;
                    });

                    this.uploader.addToQueue(files);
                  }

                  this.uploader.uploadAll();
                },
                (err) => {
                  let subTitle = '';
                  switch (err.status) {
                    case 500:
                      subTitle = '서버에러';
                      break;
                    default:
                      subTitle = '잘못된 요청입니다.';
                      break;
                  }
                  this.dialogService.message("알림", subTitle);
                }
              );
          }
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  footerImageRemove(photo) {
    this.dialogService.loadingSubject.next('spinner');

    this.fileService.remove(photo._id)
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .subscribe(
        () => {
          this.uploader.clearQueue();
          if (photo.tag == 'FOOTER')
            this.footerImage = null;
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

}
