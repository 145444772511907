/**
 * Created by Andy Shin Yoon Yong on 14/04/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Andy Shin Yoon Yong - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andy Shin Yoon Yong <developer@applicat.co.kr>, 12/07/2017
 *
 */

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'video_image_type'})
export class VideoImageTypePipe implements PipeTransform {
  constructor() {
  }

  transform(value: string): any {
    if (!value) return 0;
    
    if (value.search(/video/gi) !== -1) {
      return 1;
    } else if (value.search(/image/gi) !== -1) {
      return 2;
    } 
    
    return 0;
  }
}