/**
 * Created by andy on 2/15/17
 * As part of web
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & andy - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by andy <>, 2/15/17
 *
 */




// Browser Lib
declare var _;

// Angular
import {OnInit, Directive, EventEmitter, Input, Output, SimpleChange} from "@angular/core";

// Angular Third Party Lib

// Project Sources


@Directive({
  selector: '[virtual-file-input]',
})
export class VirtualFileInput implements OnInit {

  virtualInput;

  @Input() controller: EventEmitter<any>;
  @Input() attributes?: any;

  eventNames = [
    "blur",
    "change",
    "focus",
    "input",
    "invalid",
    // "onreset",
    // "onsearch",
    // "onselect",
    // "onsubmit"
    // "onabort",
    // "onauxclick",
    // "onbeforecopy",
    // "onbeforecut",
    // "onbeforepaste",
    // "oncancel",
    // "oncanplay",
    // "oncanplaythrough",
    // "onclick",
    // "onclose",
    // "oncontextmenu",
    // "oncopy",
    // "oncuechange",
    // "oncut",
    // "ondblclick",
    // "ondrag",
    // "ondragend",
    // "ondragenter",
    // "ondragleave",
    // "ondragover",
    // "ondragstart",
    // "ondrop",
    // "ondurationchange",
    // "onemptied",
    // "onended",
    // "onerror",
    // "ongotpointercapture",
    // "onkeydown",
    // "onkeypress",
    // "onkeyup",
    // "onload",
    // "onloadeddata",
    // "onloadedmetadata",
    // "onloadstart",
    // "onlostpointercapture",
    // "onmessage",
    // "onmousedown",
    // "onmouseenter",
    // "onmouseleave",
    // "onmousemove",
    // "onmouseout",
    // "onmouseover",
    // "onmouseup",
    // "onmousewheel",
    // "onmozfullscreenchange",
    // "onmozfullscreenerror",
    // "onmozpointerlockchange",
    // "onmozpointerlockerror",
    // "onpaste",
    // "onpause",
    // "onplay",
    // "onplaying",
    // "onpointercancel",
    // "onpointerdown",
    // "onpointerenter",
    // "onpointerleave",
    // "onpointermove",
    // "onpointerout",
    // "onpointerover",
    // "onpointerup",
    // "onprogress",
    // "onratechange",
    // "onresize",
    // "onscroll",
    // "onseeked",
    // "onseeking",
    // "onselectstart",
    // "onshow",
    // "onstalled",
    // "onsuspend",
    // "ontimeupdate",
    // "ontoggle",
    // "onvolumechange",
    // "onwaiting",
    // "onwebglcontextcreationerror",
    // "onwebglcontextlost",
    // "onwebglcontextrestored",
    // "onwebkitfullscreenchange",
    // "onwebkitfullscreenerror"
  ];

  @Output() public inputEvent: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.virtualInput = document.createElement("input");
    this.virtualInput.setAttribute("type", "file");
    this.virtualInput.setAttribute("webkitdirectory", "");
    this.virtualInput.setAttribute("mozdirectory", "");
    this.virtualInput.setAttribute("msdirectory", "");
    this.virtualInput.setAttribute("odirectory", "");
    this.virtualInput.setAttribute("directory", "");
    this.hookEvent();
    this.controller.subscribe(this.hookController.bind(this));
  }


  hookController(commend) {
    switch (commend) {
      case "selectFile":
        return this.openFileExplorer();
      case "clear":
        return this.clear();
      default:
        return;
    }
  }

  clear(){
    this.virtualInput.value = null;
  }

  hookEvent() {
    _.forEach(this.eventNames, (eventName) => {
      this.virtualInput.addEventListener(eventName, (event) => {
        this.emitEvent(eventName, event);
      });
    });
  }

  emitEvent(eventName, data) {
    this.inputEvent.emit({
      eventName: eventName,
      data: data
    });
  }

  openFileExplorer() {
    this.virtualInput.click();
  }

}
