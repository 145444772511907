/**
 * Created by Andy Shin Yoon Yong on 14/04/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & Andy Shin Yoon Yong - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andy Shin Yoon Yong <developer@applicat.co.kr>, 12/07/2017
 *
 */

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'lpad'})
export class LPADPipe implements PipeTransform {


  constructor() {
  }

  transform(value: any, operation: number): string {
    return this.LPAD(value + "", '0', operation);
  }

  LPAD(s, c, n) {
    if (!s || !c || s.length >= n) {
      return s;
    }
    let max = (n - s.length) / c.length;
    for (let i = 0; i < max; i++) {
      s = c + s;
    }
    return s;
  }

}
