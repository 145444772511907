/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Browser Lib
declare var _;
declare var Papa;

// Angular
import {Component, OnInit, HostListener} from '@angular/core';
import {MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {throttleable} from "../../../shared/services/util";
import {SerialService} from "../../../shared/services/serial.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";

@Component({
  selector: 'serial-form',
  template: getTemplate('pages/serial/serial-form/serial-form')
})
export class SerialForm implements OnInit {

  uploadType;

  serialId;
  serial;

  uploadSerials;

  formLabel;

  contentSize;

  constructor(public dialogRef: MdDialogRef<SerialForm>,
              private serialService: SerialService,
              private dialogService: DialogService) {
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    this.recalcSize();

    this.uploadType = 'SINGLE';

    this.serial = {
      serialNumber: '',
      productName: '',
      status: 'Ready'
    };

    this.uploadSerials = [];

    if (!this.serialId){
      this.formLabel = '시리얼등록';
    } else {
      this.formLabel = '시리얼수정';
      this.loadItem();
    }
  }

  /*****************************
   *        util functions
   *****************************/

  @HostListener('window:resize')
  @throttleable(5)
  recalcSize() {
    this.contentSize = this.dialogService.calcContentSize();
  }

  isValid() {
    if(this.uploadType == 'SINGLE'){
      if(!this.serial['serialNumber'] || this.serial['serialNumber'].length <= 0) return false;
      else if(!this.serial['productName'] || this.serial['productName'].length <= 0) return false;
      else if(!this.serial['status'] || this.serial['status'].length <= 0) return false;
      else return true;
    } else {
      if(this.uploadSerials.length <= 0) return false;
      else return true
    }
  }

  uploadCSV(event){
    this.dialogService.loadingSubject.next('spinner');
    let file = event.srcElement.files[0];

    if(!file.name.match(/\.(csv)$/i)){
      event.srcElement.value = null;
      this.dialogService.message("알림", "CSV파일만 업로드하실 수 있습니다.");
      this.dialogService.loadingSubject.next('hide');
    } else if(file.size > 1024 * 1024 * 10){
      event.srcElement.value = null;
      this.dialogService.message("알림", "10MB이하 파일로 업로드해주세요.");
      this.dialogService.loadingSubject.next('hide');
    } else {
      Papa.parse(file, {
        encoding: 'UTF-8',
        complete: (results) => {
          _.forEach(results['data'], (data) => {
            if((data[0] && data[0] != '') && (data[1] && data[1] != '')){
              this.uploadSerials.push({
                serialNumber: data[0],
                productName: data[1]
              })
            }
          });
          this.dialogService.loadingSubject.next('hide');
        }
      });
    }
  }

  submit() {
    if(!this.serialId){
      if(this.uploadType == 'SINGLE') this.create();
      else this.multipleCreate();
    } else{
      this.update();
    }
  }

  dismiss() {
    this.dialogRef.close();
  }

  /*****************************
   *       helper functions
   *****************************/

  loadItem() {
    this.serialService.findOne({
      query: {_id: this.serialId},
    })
      .subscribe(
        (serialWrapper) => {
          this.serial = serialWrapper.serial;
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 400:
              subTitle = '시리얼을 찾을 수 없습니다.';
              break;
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  create() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.serialService.create(this.serial)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

  multipleCreate(){
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.serialService.multipleCreate(this.uploadSerials)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (result) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          }
        );
    }
  }

  update() {
    if(this.isValid()){
      this.dialogService.loadingSubject.next('spinner');

      this.serialService.update(this.serial)
        .finally(() => {
          this.dialogService.loadingSubject.next('hide');
        })
        .subscribe(
          (data) => {
            this.dialogRef.close();
          },
          (err) => {
            let subTitle = '';
            switch (err.status) {
              case 500:
                subTitle = '서버에러';
                break;
              default:
                subTitle = '잘못된 요청입니다.';
                break;
            }
            this.dialogService.message("알림", subTitle);
          });
    } else {
      this.dialogService.message("알림", "입력한 정보를 확인해주세요.");
    }
  }

}
