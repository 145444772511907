/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Angular
import {Component, OnInit} from '@angular/core';

// Angular Third Party Lib
import {MdDialog, MdDialogConfig, MdDialogRef} from "@angular/material";

// Project Sources
import {getTemplate} from "../../../shared/templates";
import {resizeImage} from "../../../shared/services/util";
import {AppService} from "../../../shared/services/app.service";
import {DialogService} from "../../../shared/components/dialog-message/dialog-message.service";
import {BannerService} from "../../../shared/services/banner.service";
import {StoreBannerForm} from "./store-banner-form/store-banner-form";
import {StoreBannerVideoForm} from "./store-banner-form/store-banner-video-form";

@Component({
  selector: 'store-banner',
  template: getTemplate('pages/banner/store-banner/store-banner')
})

export class StoreBanner implements OnInit {

  appEventDisposor;

  options = {
    rows: [],
    selected: null,
    headerHeight: "60px",
    footerHeight: "100px",
    rowHeight: "80px",
    bodyHeight: "640px",
    limit: 8,
    count: 0,
    page: 1,
    sortAscending: 'icon-down',
    sortDescending: 'icon-up',
    pagerLeftArrow: 'prev1',
    pagerRightArrow: 'next1',
    pagerPrevious: 'prevEnd',
    pagerNext: 'nextEnd'
  };

  dialogUserRef: MdDialogRef<StoreBannerForm>;
  dialogUserVideoRef: MdDialogRef<StoreBannerVideoForm>;
  config: MdDialogConfig = new MdDialogConfig();

  resize = resizeImage;

  searchKeyword;

  constructor(private mdDialog: MdDialog,
              private appService: AppService,
              private dialogService: DialogService,
              private bannerService: BannerService) {}

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.appEventDisposor) this.appEventDisposor.unsubscribe();
    this.appEventDisposor = this.appService.appEvent.subscribe(this.appEventHandler.bind(this));

    this.loadList({page: this.options.page});
  }

  // ngOnDestroy() {
  //   if (this.appEventDisposor)
  //     this.appEventDisposor.unsubscribe();
  // }

  /*****************************
   *        util functions
   *****************************/

  appEventHandler(event) {
    switch (event.name) {
      case 'reload':
        this.loadList({page: this.options.page});
        break;
    }
  }

   openStoreBannerFormModel(banner?, imageVideo?) {
      this.dialogService.resizeModal(this.config);
      this.config.disableClose = true;
      if(imageVideo == undefined)
        imageVideo = banner.photo.fileType.split('/')[0];
      if(imageVideo === 'video'){
          this.openStoreBannerVideoFormModel(banner);
      }else{
          if(banner && banner.imageVideo === 'video'){
              this.openStoreBannerVideoFormModel(banner);
          }else{
              this.dialogUserRef = this.mdDialog.open(StoreBannerForm, this.config);
              if (banner)
                  this.dialogUserRef.componentInstance.storeBannerId = banner._id;
              this.dialogUserRef.afterClosed()
                  .subscribe(result => {
                      this.loadList({page: this.options.page});
                  });
          }
      }
  }

  openStoreBannerVideoFormModel(banner) {
      this.dialogUserVideoRef = this.mdDialog.open(StoreBannerVideoForm, this.config);
      if (banner)
          this.dialogUserVideoRef.componentInstance.storeBannerId = banner._id;

      this.dialogUserVideoRef.afterClosed()
          .subscribe(result => {
              this.loadList({page: this.options.page});
          });
  }


  /*****************************
   *       helper functions
   *****************************/
  loadList(event) {
    this.options.page = event.page;

    let skip = (this.options.page - 1) * this.options.limit;
    let params: any = {
      query: {category: 'STORE'},
      limit: this.options.limit,
      skip: skip,
      sort: { displayOrder : 1 },
      populate: ['photo']
    };

    if (this.searchKeyword)
      params.query['url'] = {$regex: ".*" + this.searchKeyword + ".*"};


    this.bannerService.find(params)
      .subscribe(
        (storeBannersWrapper) => {
          _.forEach(storeBannersWrapper['banners'], (banner, index) => {
              banner['no'] = skip + index + 1;
              if(!banner['photo'] && banner['imageVideo'] === 'video'){
                  banner['photo'] = {};
              }
          });
          for(var i =0 ; i < storeBannersWrapper['banners'].length;i++){
            var fileType = storeBannersWrapper['banners'][i].photo.fileType.split('/');
            storeBannersWrapper['banners'][i].fileTypes = fileType[0];
          }
          if(storeBannersWrapper['banners'].length > this.options.limit){
            storeBannersWrapper['banners'].pop();
          }
          this.options.rows = storeBannersWrapper['banners'];
          this.options.count = storeBannersWrapper['total'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  storeBannerRemove(banner) {
    this.dialogService.confirm("알림", "정말 지우시겠습니까?")
      .finally(() => {
        this.dialogService.loadingSubject.next('hide');
      })
      .filter(function (x) {
        return x;
      })
      .flatMap((result) => {
        this.dialogService.loadingSubject.next('spinner');
        return this.bannerService.remove(banner._id);
      })
      .subscribe(
        (result) => {
          this.loadList({page: this.options.page});
        }
      )
  }

  refreshList() {
    let skip = (this.options.page - 1) * this.options.limit;
    let params: any = {
      query: {category: 'STORE'},
      limit: this.options.limit,
      skip: skip,
      sort: { displayOrder : 1 },
      populate: ['photo']
    };

    if (this.searchKeyword)
      params.query['url'] = {$regex: ".*" + this.searchKeyword + ".*"};


    this.bannerService.find(params)
      .subscribe(
        (storeBannersWrapper) => {
          _.forEach(storeBannersWrapper['banners'], (banner, index) => {
              banner['no'] = skip + index + 1;
              if(!banner['photo'] && banner['imageVideo'] === 'video'){
                  banner['photo'] = {};
              }
          });
          for(var i =0 ; i < storeBannersWrapper['banners'].length;i++){
            var fileType = storeBannersWrapper['banners'][i].photo.fileType.split('/');
            storeBannersWrapper['banners'][i].fileTypes = fileType[0];
          }
          if(storeBannersWrapper['banners'].length > this.options.limit){
            storeBannersWrapper['banners'].pop();
          }
          this.options.rows = storeBannersWrapper['banners'];
          this.options.count = storeBannersWrapper['total'];
        },
        (err) => {
          let subTitle = '';
          switch (err.status) {
            case 500:
              subTitle = '서버에러';
              break;
            default:
              subTitle = '잘못된 요청입니다.';
              break;
          }
          this.dialogService.message("알림", subTitle);
        }
      );
  }

  changeDisplayOrder(params, banner) {
    this.bannerService.find(params)
      .subscribe(
        (webBannersWrapper) => {
          if (webBannersWrapper['total'] > 0) {
            let otherBanner = webBannersWrapper['banners'][0];
            
            let currentDisplayOrder = banner.displayOrder;
            let otherDisplayOrder = otherBanner.displayOrder;

            banner.displayOrder = otherDisplayOrder;
            this.bannerService.update(banner)
              .finally(() => {
              })
              .subscribe(
                (data) => {
                  otherBanner.displayOrder = currentDisplayOrder;
                  this.bannerService.update(otherBanner)
                    .finally(() => {
                    })
                    .subscribe(
                      (data) => {
                        this.refreshList();
                      },
                      (err) => {
                      });
                },
                (err) => {
                });
          }
        },
        (err) => {
        }
      );
  }

  onMoveDown(banner?) {
    let params: any = {
      query: { category: 'STORE', isDeleted: false, displayOrder: { $gt : banner.displayOrder } },
      limit: 1,
      sort: { displayOrder: 1 }
    };

    this.changeDisplayOrder(params, banner);
  }

  onMoveUp(banner?) {
    let params: any = {
      query: { category: 'STORE', isDeleted: false, displayOrder: { $lt : banner.displayOrder } },
      limit: 1,
      sort: { displayOrder: -1 }
    };

    this.changeDisplayOrder(params, banner);
  }

}
