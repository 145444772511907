/**
 * Created by sungwookim on 08/05/2017
 * As part of Nosework
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by sungwookim <ksw1652@gmail.com>, 08/05/2017
 *
 * Updater    수정자 - 작성자이름 08/05/2017
 */

// Angular
import { Pipe, PipeTransform } from '@angular/core';

//입력받은 문자열을 password format으로 단순 치환
@Pipe({name: 'password'})
export class PasswordPipe implements PipeTransform {

  transform(value: string): any {
    let result = "";

    for(let i=0; i< value.length; i++) {
      result = result + "•";
    }

    return result;
  }
}
