/**
 * Created by andyshin on 11/04/2017.
 */


/**
 * Created by andy on 02/11/2016
 * As part of cafe-1-template
 *
 * Copyright (C) Applicat (www.applicat.co.kr) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Andy Yoon Yong Shin <developer@applicat.co.kr>, 02/11/2016
 *
 * Updater    수정자 - PHILIP 21/03/2017
 */

// Angular
import {Directive, Input, ElementRef, Renderer, OnInit, SimpleChange, OnChanges} from "@angular/core";

@Directive({
  selector: '[sanitize-attribute]'
})

export class SanitizeAttribute implements OnInit, OnChanges {
  public element;

  @Input('sanitize-attribute') attributes: any;

  constructor(private renderer: Renderer,
              private elementRef: ElementRef) {
    this.element = elementRef;
  }

  /*****************************
   *         life cycle
   *****************************/

  ngOnInit() {
    if (this.attributes)
      this.updateAttributes();
  }

  ngOnChanges(changes: {[key: string]: SimpleChange}) {
    if (changes['attributes']) {
      this.updateAttributes();
    }
  }

  updateAttributes() {
    for (var key in this.attributes) {
      if (!this.element.nativeElement[key])
        this.renderer.setElementAttribute(this.element.nativeElement, key, this.attributes[key]);
    }
  }

}
