/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Browser Lib
import {Observable} from "rxjs";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party Lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor/http/interceptable-http";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class PopUpService {
  serverUrl = config.serverUrl + '/popup';

  constructor(private http: InterceptableHttp) {}

  find(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/find';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key)=> {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  findOne(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOne';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key)=> {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  create(template: any): Observable<any> {
    return this.http
      .post(this.serverUrl, template);
  }

  update(template: any): Observable<any> {
    return this.http
      .put(this.serverUrl, template);
  }

  remove(id): Observable<any> {
    let params: URLSearchParams = new URLSearchParams();
    params.set("_id", id.toString());

    return this.http
      .delete(this.serverUrl, {search: params});
  }

}
