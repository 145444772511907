/**
 * Created by PHILIP on 12/07/2017
 * As part of NoseWork
 *
 * Copyright (C) Applicat (www.applicat.co.kr) & PHILIP - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by PHILIP <hbnb7894@gmail.com>, 12/07/2017
 *
 */

// Export Lib
declare var _;

// Browser Lib
import {Observable} from "rxjs";

// Angular
import {Injectable} from "@angular/core";
import {URLSearchParams} from "@angular/http";

// Angular Third Party Lib
import {InterceptableHttp} from "../../lib/ng-http-interceptor/http/interceptable-http";

// Project Sources
import {config} from "../../app/app.config";

@Injectable()
export class TransactionService{
  serverUrl = config.serverUrl + '/transaction';

  constructor(private http: InterceptableHttp){}

  findInvoice(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findInvoice';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key)=> {
        params.set(key, JSON.stringify(value));//JSON.stringify(value)
    });
    // for(var i =0 ; i < queryParams.length;i++){
    //   params.set(Object.keys(queryParams)[i] , queryParams.value);
    // }
    return this.http
      .get(url, {search: params});
  }

  findOneInvoice(queryParams: any): Observable<any> {
    let url = this.serverUrl + '/findOneInvoice';

    let params: URLSearchParams = new URLSearchParams();
    _.forEach(queryParams, (value, key)=> {
      params.set(key, JSON.stringify(value));
    });

    return this.http
      .get(url, {search: params});
  }

  //added by ssr - 2018/03/24
  downloadExcel(queryParams: any) {
    let url = this.serverUrl + '/downloadExcel';
    
    let params = JSON.stringify(queryParams);
    url += "?search=" + params;
    window.location.href = url;
  }

  changeStatus(paymentId, status): Observable<any>{
    let url = this.serverUrl + '/changeStatus';
    
    return this.http
      .put(url, {paymentId: paymentId, status: status});
  }

  changeTransInfo(param): Observable<any>{
    let url = this.serverUrl + '/changeTransInfo';

    return this.http
      .put(url, param);
  }

}